import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../../app/common/reduxs/async/asyncActions';
import {
  cbtquestionsGet,
  cbtquestionAdd,
  cbtquestionGet,
  cbtquestionEdit,
  cbtparticipantGet,
  cbtparticipantCbtquestionDelete,
  cbtparticipantGroupGet,
  cbtquestionUploadFile,
  cbtquestionDelete,
  cbtquestionResetSearch,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../../app/common/helpers/fetchHelpers';
import { logsFunc } from '../../../../../app/common/helpers/othersHelpers';
import { SITE_ADDRESS } from '../../../../../app/common/data/siteConfig';
import { saveAs } from 'file-saver';
import { nanoid } from 'nanoid';

export const getCbtquestions = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText,
  searchMoreText,
  isReset
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getCbtquestions' });
    const formData = new FormData();
    if (isReset === 'reset') {
      dispatch(cbtquestionResetSearch());
    }
    const allDataAccess = auth.authorities.subm.materi_all;
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    formData.append('allData', allDataAccess);
    formData.append('searchMoreText', JSON.stringify(searchMoreText));
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'cbtquestion/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
        ...searchMoreText,
      };
      dispatch(cbtquestionsGet({ total, items, search }));
      if (isReset === 'reset') {
        history.push('/cbt/soalcbt');
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addCbtquestion = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addCbtquestion' });
    let logs = logsFunc('create', auth.userid, auth.username, null);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('questions', JSON.stringify(values.questions));
    formData.append('answers', JSON.stringify(values.answers));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'cbtquestion/add/' + values.id,
        formData,
        dispatch
      );
      const cbtquestion = res.cbtquestion;
      dispatch(cbtquestionAdd(cbtquestion));
      history.push('/cbt/soalcbt/edit/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getCbtquestion = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getCbtquestion' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'cbtquestion/detail/' + id,
        formData,
        dispatch
      );
      const cbtquestion = res.cbtquestion;
      dispatch(cbtquestionGet(cbtquestion));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editCbtquestion = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editCbtquestion' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('questions', JSON.stringify(values.questions));
    formData.append('answers', JSON.stringify(values.answers));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'cbtquestion/edit/' + values.id,
        formData,
        dispatch
      );
      const cbtquestion = res.cbtquestion;
      dispatch(cbtquestionEdit(cbtquestion));
      history.push('/cbt/soalcbt/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const publishCbtquestion = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'publishCbtquestion' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('published', values.published);
    formData.append('timer', JSON.stringify(values.timer));
    formData.append(
      'group',
      JSON.stringify(values.group ? values.group : undefined)
    );
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'cbtquestion/publish/' + values.id,
        formData,
        dispatch
      );
      const cbtquestion = res.cbtquestion;
      dispatch(cbtquestionEdit(cbtquestion));
      history.push('/cbt/soalcbt/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getCbtparticipant = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getCbtparticipant' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'cbtquestion/cbtparticipant/' + id,
        formData,
        dispatch
      );
      const cbtparticipants = res.cbtparticipants;
      dispatch(cbtparticipantGet(cbtparticipants));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteCbtquestionCbtparticipantAnswer = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteCbtquestionCbtparticipantAnswer' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'cbtquestion/cbtparticipant-answer-delete/' + id,
        formData,
        dispatch
      );
      const cbtparticipantCbtquestion = res.cbtparticipantCbtquestion;
      dispatch(cbtparticipantCbtquestionDelete(cbtparticipantCbtquestion));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteCbtquestionCbtparticipantAllAnswer = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteCbtquestionCbtparticipantAnswer' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'cbtquestion/cbtparticipant-allanswer-delete/' + id,
        formData,
        dispatch
      );
      const cbtparticipantCbtquestion = res.cbtparticipantCbtquestion;
      dispatch(cbtparticipantCbtquestionDelete(cbtparticipantCbtquestion));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const submitScores = (auth, history, id, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'submitScores' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('scores', JSON.stringify(values));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'cbtquestion/submit-scores/' + id,
        formData,
        dispatch
      );
      const cbtquestion = res.cbtquestion;
      dispatch(cbtquestionEdit(cbtquestion));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getCbtparticipantGroup = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getCbtparticipantGroup' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'cbtquestion/cbtparticipant-group/',
        formData,
        dispatch
      );
      const cbtparticipantGroup = res.cbtparticipantGroup;
      dispatch(cbtparticipantGroupGet(cbtparticipantGroup));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const importCbtquestion = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'importCbtquestion' });
    try {
      let questions = [];
      let answers = [];
      for (let i = 0; i < values.numberOfQuestion; i++) {
        let item = values.data[i];
        let key = nanoid();
        let tipe = item.Tipe.toLowerCase();
        let questionType =
          tipe === 'pilihan'
            ? 'optionQuestion'
            : tipe === 'singkat'
            ? 'shortQuestion'
            : 'longQuestion';
        let newQuestion = {
          no: item.No,
          key: key,
          questionType: questionType,
          question: item.Pertanyaan,
        };
        newQuestion.options =
          tipe === 'pilihan' ? item.Pilihan.split('\r\n') : [];
        questions = [...questions, newQuestion];
        let newAnswer = {
          no: item.No,
          key: key,
          answer: item.Jawaban,
          explanation: item.Penjelasan,
        };
        answers = [...answers, newAnswer];
      }
      let logs = logsFunc('create', auth.userid, auth.username, null);
      const formData = new FormData();
      formData.append('id', nanoid(32));
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('questions', JSON.stringify(questions));
      formData.append('answers', JSON.stringify(answers));
      formData.append('logs', logs);
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'cbtquestion/import/',
        formData,
        dispatch
      );
      const cbtquestion = res.importResult;
      dispatch(cbtquestionAdd(cbtquestion));
      history.push('/cbt/soalcbt/edit/' + cbtquestion.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const uploadImage = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'uploadImage' });
    dispatch(
      cbtquestionUploadFile({
        key: values.keyItem,
        filename: values.filename,
        filesize: values.filesize,
        filetype: values.filetype,
        status: 'start',
      })
    );
    let logAction = values.logs ? 'edit' : 'create';
    let logs = logsFunc(logAction, auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('keyItem', values.keyItem);
    formData.append('questions', JSON.stringify(values.questions));
    formData.append('answers', JSON.stringify(values.answers));
    formData.append('file', values.file);
    formData.append('filename', values.filename);
    formData.append('filesize', values.filesize);
    formData.append('filetype', values.filetype);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'uploadAttach',
        auth,
        history,
        'cbtquestion/upload-image/' + values.id,
        formData,
        dispatch
      );
      const cbtquestion = res.cbtquestion;
      dispatch(cbtquestionEdit(cbtquestion));
      dispatch(
        cbtquestionUploadFile({
          key: values.keyItem,
          filename: values.filename,
          filesize: values.filesize,
          filetype: values.filetype,
          status: 'finish',
        })
      );
      dispatch(asyncActionFinish());
      history.push('/cbt/soalcbt/edit/' + values.id);
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const viewImage = (auth, history, values, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'viewImage' });
    const formData = new FormData();
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    formData.append('link', file.link);
    try {
      const fetchData = await fetch(
        SITE_ADDRESS + 'cbtquestion/download-file/' + values.id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const blob = await fetchData.blob();
      saveAs(blob, file.link.split('/')[file.link.split('/').length - 1]);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteImage = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteImage' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('questionKey', values.key);
    formData.append('link', values.picture.filelink);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'deleteFile',
        auth,
        history,
        'cbtquestion/delete-image/' + values.id,
        formData,
        dispatch
      );
      const cbtquestion = res.cbtquestion;
      dispatch(cbtquestionEdit(cbtquestion));
      history.push('/cbt/soalcbt/edit/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteCbtquestion = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteCbtquestion' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'cbtquestion/delete/' + id,
        formData,
        dispatch
      );
      const cbtquestion = res.cbtquestion;
      dispatch(cbtquestionDelete(cbtquestion));
      history.push('/cbt/soalcbt');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
