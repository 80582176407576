import React from 'react';
import ReactDOM from 'react-dom';
import './app/main/additional.styles.css';
import './app/main/styles.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import App from './app/main/App';
// import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from './app/store/configureStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReduxToastr from 'react-redux-toastr';
import ScrollToTop from './app/common/utilities/ScrollToTop';

const { store, persistor } = configureStore();

const rootEl = document.getElementById('root');

function render() {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <ReduxToastr
            position='bottom-right'
            transitionIn='fadeIn'
            transitionOut='fadeOut'
          />
          <PersistGate persistor={persistor}>
            <App />
          </PersistGate>
        </ScrollToTop>
      </BrowserRouter>
    </Provider>,
    rootEl
  );
}

if (module.hot) {
  module.hot.accept('./app/main/App', function () {
    setTimeout(render);
  });
}

render();

// serviceWorker.register();
