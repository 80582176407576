import React from 'react';
import { connect } from 'react-redux';
import { deleteFileAnnouncement } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';
import Modal from '../../../ui/Modal';
import Button from '../../../ui/Button';

const actions = {
  closeModal,
  deleteFileAnnouncement,
};

function ModalDeleteFile({ data, deleteFileAnnouncement, closeModal }) {
  const { auth, history, item, file } = data;

  function handleDelete() {
    deleteFileAnnouncement(auth, history, item, file);
    closeModal();
  }

  return (
    <Modal variant='danger' closeModal={closeModal}>
      <p className='my-3 mx-1'>
        Apakah Anda yakin ingin menghapus file{' '}
        <span className='has-text-danger has-text-weight-semibold'>{`${file.filename}`}</span>
        ?
      </p>
      <div className='flex justify-end align-center pt-2'>
        <div className='buttons'>
          <Button cvariant='cdelete' onClick={handleDelete} />
          <Button cvariant='cback' onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, actions)(ModalDeleteFile);
