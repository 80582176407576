import React from 'react';
import MSyncScoreFormListItems from './MSyncScoreFormListItems';

export default function MSyncScoreFormList(props) {
  const { scores, listScores } = props;
  return (
    <>
      {listScores &&
        listScores.map((item, index) => (
          <MSyncScoreFormListItems
            key={item.NIS}
            index={index}
            scores={scores}
            item={item}
          />
        ))}
      {listScores && listScores.length === 0 && (
        <tr>
          <td colSpan='7'>
            Tidak ada <span className='is-lowercase'>data</span>
          </td>
        </tr>
      )}
    </>
  );
}
