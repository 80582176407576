import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MViewSummaryAnswerForm from './MViewSummaryAnswerForm';
import { closeModal } from '../../../app/modals/redux/modalActions';
import { submitTesScores } from './redux/reduxApi';

const mapState = (state, ownProps) => {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  return {
    loading: state.async.loading,
    scope: scope,
  };
};

const actions = {
  closeModal,
  submitTesScores,
};

function ModalViewSummaryAnswer(props) {
  const { loading, scope, data, submitTesScores, closeModal } = props;
  const history = useHistory();
  const auth = data && data.auth;
  const id = data && data.id;
  const studentDetail = data && data.item;
  const answers =
    data && data.item && data.item.answers && JSON.parse(data.item.answers);
  const questions = data && data.questions;
  const keyAnswers = data && data.keyAnswers;
  const stateScores = data && data.stateScores;
  const scores = data && data.scores;
  const optScore = data && data.optScore;
  let selectedScore =
    scores && scores.find((i) => i.studentTesId + '' === studentDetail.id + '');
  let finalScore;
  let detailScore;
  if (!selectedScore) {
    let selectedStateScore = stateScores.find(
      (i) => i.studentTesId + '' === studentDetail.id + ''
    );
    finalScore = selectedStateScore?.finalScore;
    detailScore = selectedStateScore?.detailScore;
  } else {
    finalScore = selectedScore?.finalScore;
    detailScore = selectedScore?.detailScore;
  }

  const onFormSubmit = async (values) => {
    console.log('submit form');
  };

  return (
    <ModalBig closeModal={closeModal}>
      <ModalHeader
        closeModal={closeModal}
        title='Summary Jawaban'
        color='info'
        icon='playlist-check'
      />
      <MViewSummaryAnswerForm
        auth={auth}
        history={history}
        scope={scope}
        loading={loading}
        closeModal={closeModal}
        id={id}
        studentDetail={studentDetail}
        initialValues={{}}
        questions={questions}
        keyAnswers={keyAnswers}
        answers={answers}
        scores={scores}
        optScore={optScore}
        finalScore={finalScore}
        detailScore={detailScore}
        submitTesScores={submitTesScores}
        onFormSubmit={onFormSubmit}
      />
      <ModalFooter closeModal={closeModal} />
    </ModalBig>
  );
}

function ModalHeader({ title, color, icon, closeModal }) {
  return (
    <header className={`modal-card-head has-background-${color}`}>
      <div className='modal-card-title has-text-white has-text-weight-normal'>
        <i className={`is-size-4 mdi mdi-${icon ? icon : 'shield'} icon`} />{' '}
        {title}
      </div>
      <button
        onClick={closeModal}
        className='delete'
        aria-label='close'
      ></button>
    </header>
  );
}

function ModalFooter({ closeModal }) {
  return (
    <footer className='modal-card-foot'>
      <button
        className='button custom-grey is-small is-rounded is-outlined'
        onClick={closeModal}
      >
        <i className='is-size-6 mdi mdi-arrow-left icon' />
      </button>
    </footer>
  );
}

function ModalBig({ children, closeModal }) {
  return (
    <div className='modal is-active'>
      <div className='modal-background' onClick={closeModal}></div>
      <div className='modal-card'>{children}</div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(ModalViewSummaryAnswer));
