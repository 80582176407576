import React, { useState, useRef, useEffect } from 'react';
import { closeModal } from '../../../app/modals/redux/modalActions';
import { connect } from 'react-redux';
import { editRemarksMonitoring } from './redux/reduxApi';
import { format, parseISO } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import ReactTooltip from 'react-tooltip';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  return {
    scope: scope,
  };
}

const actions = {
  editRemarksMonitoring,
  closeModal,
};

const TextArea = ({
  name,
  type,
  onChange,
  onFocus,
  disabled,
  readOnly,
  defaultValue,
  placeholder,
  label,
  style,
  innerRef,
}) => (
  <div className='field'>
    {label && label !== 'noLabel' && (
      <label className='label is-size-6 has-text-weight-normal' htmlFor={name}>
        {label}
      </label>
    )}
    <div className='control'>
      <textarea
        ref={innerRef}
        className='textarea'
        id={name}
        onChange={onChange}
        onFocus={onFocus}
        value={defaultValue}
        type={type}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        style={{ ...style }}
      />
    </div>
  </div>
);

function ModalPesanView({ closeModal, editRemarksMonitoring, data }) {
  const { auth, history, id, tes, student, message, profilePic } = data;
  const inputRef = useRef(null);
  const [input, setInput] = useState(message ? message.message : '');
  const [editState, setEditState] = useState(false);

  useEffect(() => {
    if (editState) {
      inputRef.current.focus();
    }
  }, [editState]);

  const onChangeInput = (e, student) => {
    e.preventDefault();
    let val = e.target.value;
    setInput(val);
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handleSend = () => {
    let remarks = tes?.remarks ? JSON.parse(tes.remarks) : {};
    let messages = remarks?.messages ? remarks.messages : [];
    let newMessage = {
      nis: student.nis,
      studentName: student.studentName,
      message: input,
      createdAt: new Date(),
      createdBy: auth.username,
    };
    if (messages && messages[0]) {
      console.log(messages);
      messages = [...messages.filter((n) => n.nis !== student.nis), newMessage];
      console.log(messages);
    } else {
      messages.push(newMessage);
    }
    let updateRemarks = { ...remarks, messages };
    let newTes = { ...tes, remarks: JSON.stringify(updateRemarks) };
    editRemarksMonitoring(auth, history, newTes, id);
    setEditState(false);
    toastr.success('Info', 'Pesan dikirim');
    closeModal();
  };

  return (
    <div className='modal is-active'>
      <div className='modal-background' onClick={closeModal}></div>
      <div
        className='modal-content flex justify-center'
        style={{ overflow: 'hidden' }}
      >
        <div className='' style={{ width: 600 }}>
          <div className='' style={{ overflow: 'hidden' }}>
            <div className='card'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: 8,
                  paddingRight: 8,
                  overflow: 'hidden',
                }}
              >
                <button
                  onClick={closeModal}
                  className='delete'
                  aria-label='close'
                ></button>
              </div>
              <div
                className='card-content'
                style={{ padding: '0px 17px 20px 15px' }}
              >
                <div className='media' style={{ alignItems: 'center' }}>
                  <div className='media-left'>
                    <figure className='image is-48x48'>
                      <img
                        src={profilePic}
                        alt='profile'
                        style={{ height: 48, width: 48 }}
                      />
                    </figure>
                  </div>
                  <div className='media-content'>
                    <p className='has-text-grey-dark has-text-weight-semibold is-size-6'>
                      {student.siswa}
                    </p>
                    <p className='has-text-grey is-size-7'>{student.nis}</p>
                  </div>
                </div>
                <div className='content' style={{ marginTop: -8 }}>
                  {!editState && message && (
                    <table>
                      <tbody>
                        <tr>
                          <td><span className='has-text-link' style={{marginLeft: -5}}><i className='mdi mdi-email-edit icon'></i>Pesan</span></td>
                        </tr>
                        <tr>
                          <td>{input}</td>
                        </tr>
                        <tr>
                          <td className='is-italic is-size-7 has-text-right'>
                            Dibuat:{' '}
                            <span className='has-text-weight-medium'>
                              {(message &&
                                format(
                                  parseISO(message.createdAt),
                                  'd LLLL yyyy - HH:mm:ss'
                                )) ||
                                '-'}{' '}
                              oleh
                            </span>{' '}
                            <span className='is-capitalized has-text-weight-medium'>
                              {(message && message.createdBy) || '-'}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  {!message && (
                    <>
                      <div className='mb-2'><span className='has-text-link ml-1'><i className='mdi mdi-email-plus icon'></i>Pesan</span></div>
                      <TextArea
                        innerRef={inputRef}
                        name='message'
                        defaultValue={input ?? ''}
                        onChange={(e) => onChangeInput(e, student)}
                        onFocus={(e) => handleFocus(e)}
                        placeholder='Isi pesan'
                        label='noLabel'
                      />
                    </>
                  )}
                  {editState && message && (
                    <>
                      <div className='mb-2'><span className='has-text-link ml-1'><i className='mdi mdi-email-edit icon'></i>Pesan</span></div>
                      <TextArea
                        innerRef={inputRef}
                        name='message'
                        defaultValue={input ?? ''}
                        onChange={(e) => onChangeInput(e, student)}
                        onFocus={(e) => handleFocus(e)}
                        placeholder='Isi pesan'
                        label='noLabel'
                      />
                    </>
                  )}
                  <div className='has-text-grey-dark is-size-7 flex justify-end mt-2'>
                    <div className='mb-2 is-italic'></div>
                    {message && !editState && (
                      <button
                        onClick={() => setEditState(true)}
                        className='button is-small is-rounded is-outlined is-info'
                      >
                        <i className='is-size-6 mdi mdi-pencil icon' />
                      </button>
                    )}
                    {editState && (
                      <>
                        <div data-for='main' data-tip='Kembali'>
                          <button
                            onClick={() => setEditState(false)}
                            className='button is-small is-rounded is-outlined custom-grey mr-2'
                          >
                            <i className='is-size-6 mdi mdi-arrow-left-thin icon' />
                          </button>
                          <ReactTooltip
                            id='main'
                            place='top'
                            type='dark'
                            effect='solid'
                            multiline={true}
                          />
                        </div>
                        <div data-for='main' data-tip='Simpan dan Kirim'>
                          <button
                            onClick={() => handleSend()}
                            className='button is-small is-rounded is-outlined is-info'
                          >
                            <i className='is-size-6 mdi mdi-email-arrow-right icon' />
                          </button>
                          <ReactTooltip
                            id='main'
                            place='top'
                            type='dark'
                            effect='solid'
                            multiline={true}
                          />
                        </div>
                      </>
                    )}
                    {!message && (
                      <button
                        onClick={() => handleSend()}
                        className='button is-small is-rounded is-outlined is-info'
                      >
                        <i className='is-size-6 mdi mdi-email-arrow-right icon' />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapState, actions)(ModalPesanView);
