import {
  CBTPARTICIPANTS_GET,
  CBTPARTICIPANT_EDIT,
  CBTPARTICIPANT_UPLOADFILE,
  CBTPARTICIPANT_DELETE,
  CBTPARTICIPANT_RESET_SEARCH,
} from './reduxConstant';
import { createReducer } from '../../../../../app/common/utilities/reducerUtils';
import { sortArrayObject } from '../../../../../app/common/helpers/objectHelpers';

const initialState = {
  total: 0,
  search: {},
  items: [],
  uploadStatus: 'finish',
};

const cbtparticipantsGet = (state, payload) => {
  const cbtparticipants = payload.cbtparticipants;
  let newItems = cbtparticipants.items
  let sortItems = sortArrayObject(newItems, 'name', 'asc');
  return {
    total: cbtparticipants.total,
    items: [...sortItems],
    search: { ...cbtparticipants.search },
  };
};

const cbtparticipantEdit = (state, payload) => {
  let newItems = [
    ...state.items.filter((i) => i.id + '' !== payload.cbtparticipant.id + ''),
    payload.cbtparticipant,
  ]
  let sortItems = sortArrayObject(newItems, 'name', 'asc');
  return {
    ...state,
    items: sortItems,
  };
};

const cbtparticipantUploadFile = (state, payload) => {
  let status = payload.status;
  return {
    ...state,
    uploadStatus: status,
  };
};

const cbtparticipantDelete = (state, payload) => {
  return {
    total: parseInt(state.total) - 1,
    items: [...state.items.filter((i) => i.id + '' !== payload.cbtparticipant.id + '')],
    search: { ...state.search },
  };
};

const cbtparticipantResetSearch = (state, payload) => {
  return {
    ...state,
    search: {
    },
  };
};

export default createReducer(initialState, {
  [CBTPARTICIPANTS_GET]: cbtparticipantsGet,
  [CBTPARTICIPANT_EDIT]: cbtparticipantEdit,
  [CBTPARTICIPANT_UPLOADFILE]: cbtparticipantUploadFile,
  [CBTPARTICIPANT_DELETE]: cbtparticipantDelete,
  [CBTPARTICIPANT_RESET_SEARCH]: cbtparticipantResetSearch,
});
