import React from 'react';
import MViewSummaryAnswerFormListItems from './MViewSummaryAnswerFormListItems';

export default function ViewSummaryFormList(props) {
  const {
    auth,
    scope,
    state,
    questions,
    onChangeInput,
    handleEnter,
    isEdit,
  } = props;
  return (
    <>
      {questions &&
        questions[0] &&
        questions.map((question, index) => (
            <MViewSummaryAnswerFormListItems
              key={index}
              index={index}
              auth={auth}
              scope={scope}
              state={state}
              question={question}
              onChangeInput={onChangeInput}
              handleEnter={handleEnter}
              isEdit={isEdit}
            />
        ))}
      {questions && questions.length === 0 && (
        <tr>
          <td colSpan='8'>Tidak ada data</td>
        </tr>
      )}
    </>
  );
}
