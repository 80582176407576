import {
  TUGASS_GET,
  TUGAS_ADD,
  TUGAS_GET,
  STUDENT_ANSWER_GET,
  TUGAS_EDIT,
  TUGAS_DETAILSCORES,
  TUGAS_LISTSCORES,
  TUGAS_UPLOADFILE,
  SETTING_GET,
  TUGAS_DELETE,
  TUGAS_RESET_SEARCH,
  TUGAS_DELETESTUDENTANSWER,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    year: '',
    sem: '',
    jenjang: '',
    tingkat: '',
    kelas: '',
    pelajaran: '',
    published: 'all',
  },
  items: [],
  studentAnswer: [],
  uploadFiles: [],
  detailScores: {},
  listScores: [],
  setting: {},
};

const tugassGet = (state, payload) => {
  const tugass = payload.tugass;
  return {
    ...state,
    total: tugass.total,
    items: [...tugass.items],
    search: { ...tugass.search },
  };
};

const tugasAdd = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) + 1,
    items: [...state.items, payload.tugas],
  };
};

const tugasGet = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.tugas.id + ''),
      payload.tugas,
    ],
  };
};

const studentAnswerGet = (state, payload) => {
  return {
    ...state,
    studentAnswer: [...payload.answers],
  };
};

const tugasEdit = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.tugas.id + ''),
      payload.tugas,
    ],
  };
};
// Sync score to academic
const tugasDetailScores = (state, payload) => {
  return {
    ...state,
    detailScores: { ...payload.detailScores },
  };
};
// Sync score to academic
const tugasListScores = (state, payload) => {
  return {
    ...state,
    listScores: [...payload.listScores],
  };
};

const tugasUploadFile = (state, payload) => {
  let uploadFile = payload.uploadFile;
  let uploadFiles =
    state.uploadFiles && state.uploadFiles ? state.uploadFiles : [];
  let filteredUploadFiles = uploadFiles.filter((i) => i.key !== uploadFile.key);
  let newUploadFiles = [...filteredUploadFiles, uploadFile];
  return {
    ...state,
    uploadFiles: newUploadFiles,
  };
};

const settingGet = (state, payload) => {
  return {
    ...state,
    setting: payload.setting,
  };
};

const tugasDeleteStudentAnswer = (state, payload) => {
  let tugas = payload?.tugas;
  if (tugas) {
    let filterStudentAnswer =
      state.studentAnswer &&
      state.studentAnswer.filter(
        (i) => i.id + '' !== tugas.studentTugasId + ''
      );
    return {
      ...state,
      studentAnswer: filterStudentAnswer,
    };
  } else {
    return {
      ...state,
      studentAnswer: [],
    };
  }
};

const tugasDelete = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) - 1,
    items: [...state.items.filter((i) => i.id + '' !== payload.tugas.id + '')],
  };
};

const tugasResetSearch = (state, payload) => {
  return {
    ...state,
    search: {
      year: '',
      sem: '',
      jenjang: '',
      tingkat: '',
      kelas: '',
      pelajaran: '',
      published: 'all',
    },
  };
};

export default createReducer(initialState, {
  [TUGASS_GET]: tugassGet,
  [TUGAS_ADD]: tugasAdd,
  [TUGAS_GET]: tugasGet,
  [STUDENT_ANSWER_GET]: studentAnswerGet,
  [TUGAS_EDIT]: tugasEdit,
  [TUGAS_DETAILSCORES]: tugasDetailScores,
  [TUGAS_LISTSCORES]: tugasListScores,
  [TUGAS_UPLOADFILE]: tugasUploadFile,
  [SETTING_GET]: settingGet,
  [TUGAS_DELETESTUDENTANSWER]: tugasDeleteStudentAnswer,
  [TUGAS_DELETE]: tugasDelete,
  [TUGAS_RESET_SEARCH]: tugasResetSearch,
});
