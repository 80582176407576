import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
import {
  latihansGet,
  latihanAdd,
  latihanGet,
  latihanEdit,
  latihanUploadFile,
  latihanDelete,
  latihanResetSearch,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../app/common/helpers/fetchHelpers';
import { logsFunc } from '../../../../app/common/helpers/othersHelpers';
import { SITE_ADDRESS } from '../../../../app/common/data/siteConfig';
import { saveAs } from 'file-saver';
import { nanoid } from 'nanoid';

export const getLatihans = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText,
  searchMoreText,
  isReset
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getLatihans' });
    const formData = new FormData();
    if (isReset === 'reset') {
      dispatch(latihanResetSearch());
    }
    const allDataAccess = auth.authorities.subm.materi_all;
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    formData.append('allData', allDataAccess);
    formData.append('searchMoreText', JSON.stringify(searchMoreText));
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'latihan/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
        ...searchMoreText,
      };
      dispatch(latihansGet({ total, items, search }));
      if (isReset === 'reset') {
        history.push('/pembelajaran/latihan');
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addLatihan = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addLatihan' });
    let logs = logsFunc('create', auth.userid, auth.username, null);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('questions', JSON.stringify(values.questions));
    formData.append('answers', JSON.stringify(values.answers));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'latihan/add/' + values.id,
        formData,
        dispatch
      );
      const latihan = res.latihan;
      dispatch(latihanAdd(latihan));
      history.push('/pembelajaran/latihan/edit/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getLatihan = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getLatihan' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'latihan/detail/' + id,
        formData,
        dispatch
      );
      const latihan = res.latihan;
      dispatch(latihanGet(latihan));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editLatihan = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editLatihan' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('questions', JSON.stringify(values.questions));
    formData.append('answers', JSON.stringify(values.answers));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'latihan/edit/' + values.id,
        formData,
        dispatch
      );
      const latihan = res.latihan;
      dispatch(latihanEdit(latihan));
      history.push('/pembelajaran/latihan/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const publishLatihan = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'publishLatihan' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('published', values.published);
    formData.append('year', values.year);
    formData.append('sem', values.sem);
    formData.append('jenjang', values.jenjang);
    formData.append('tingkat', values.tingkat);
    formData.append('kelas', values.kelas.toString());
    formData.append('pelajaran', values.pelajaran);
    formData.append('tags', JSON.stringify(values.tags));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'latihan/publish/' + values.id,
        formData,
        dispatch
      );
      const latihan = res.latihan;
      dispatch(latihanEdit(latihan));
      history.push('/pembelajaran/latihan/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const importLatihan = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'importLatihan' });
    try {
      let questions = [];
      let answers = [];
      for (let i = 0; i < values.numberOfQuestion; i++) {
        let item = values.data[i];
        let key = nanoid();
        let tipe = item.Tipe.toLowerCase();
        let questionType;
        if (tipe === 'pilihan') {
          questionType = 'optionQuestion';
        } else if (tipe === 'b/s') {
          questionType = 'rightWrongQuestion';
        } else if (tipe === 'singkat') {
          questionType = 'shortQuestion';
        } else {
          questionType = 'longQuestion';
        }
        let newQuestion = {
          no: i + 1,
          key: key,
          questionType: questionType,
          question: item.Pertanyaan,
        };
        newQuestion.options =
          tipe === 'pilihan' || tipe === 'b/s'
            ? item.Pilihan.split('\r\n')?.filter((el) => {
                return el;
              })
            : [];
        questions = [...questions, newQuestion];
        let newAnswer = {
          no: i + 1,
          key: key,
          answer: item.Jawaban,
          explanation: item.Penjelasan,
        };
        answers = [...answers, newAnswer];
      }
      let logs = logsFunc('create', auth.userid, auth.username, null);
      const formData = new FormData();
      formData.append('id', nanoid(32));
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('questions', JSON.stringify(questions));
      formData.append('answers', JSON.stringify(answers));
      formData.append('logs', logs);
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'latihan/import/',
        formData,
        dispatch
      );
      const importResult = res.importResult;
      history.push('/pembelajaran/latihan/edit/' + importResult.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const uploadImage = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'uploadImage' });
    dispatch(
      latihanUploadFile({
        key: values.keyItem,
        filename: values.filename,
        filesize: values.filesize,
        filetype: values.filetype,
        status: 'start',
      })
    );
    let logAction = values.logs ? 'edit' : 'create';
    let logs = logsFunc(logAction, auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('keyItem', values.keyItem);
    formData.append('questions', JSON.stringify(values.questions));
    formData.append('answers', JSON.stringify(values.answers));
    formData.append('file', values.file);
    formData.append('filename', values.filename);
    formData.append('filesize', values.filesize);
    formData.append('filetype', values.filetype);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'uploadAttach',
        auth,
        history,
        'latihan/upload-image/' + values.id,
        formData,
        dispatch
      );
      const latihan = res.latihan;
      dispatch(latihanEdit(latihan));
      dispatch(
        latihanUploadFile({
          key: values.keyItem,
          filename: values.filename,
          filesize: values.filesize,
          filetype: values.filetype,
          status: 'finish',
        })
      );
      dispatch(asyncActionFinish());
      history.push('/pembelajaran/latihan/edit/' + values.id);
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const viewImage = (auth, history, values, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'viewImage' });
    const formData = new FormData();
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    formData.append('link', file.link);
    try {
      const fetchData = await fetch(
        SITE_ADDRESS + 'latihan/download-file/' + values.id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const blob = await fetchData.blob();
      saveAs(blob, file.link.split('/')[file.link.split('/').length - 1]);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteImage = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteImage' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('questionKey', values.key);
    formData.append('link', values.picture.filelink);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'deleteFile',
        auth,
        history,
        'latihan/delete-image/' + values.id,
        formData,
        dispatch
      );
      const latihan = res.latihan;
      dispatch(latihanEdit(latihan));
      history.push('/pembelajaran/latihan/edit/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteLatihan = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteLatihan' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'latihan/delete/' + id,
        formData,
        dispatch
      );
      const latihan = res.latihan;
      dispatch(latihanDelete(latihan));
      history.push('/pembelajaran/latihan/');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
