import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { closeModal } from '../../../app/modals/redux/modalActions';
import { connect } from 'react-redux';
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
// import DateInput from '../../../app/common/form/DateInput';
import { reduxForm, Field } from 'redux-form';
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan,
} from 'revalidate';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  return {
    auth: state.auth,
    scope: scope,
    loading: state.async.loading,
  };
}

const actions = {
  closeModal,
};

const validate = combineValidators({
  name: composeValidators(
    isRequired({ message: 'Nama perusahaan harus diisi' }),
    hasLengthGreaterThan(1)({
      message: 'Nama Perusahaan harus memiliki paling sedikit 2 karakter',
    })
  )(),
});

function ModalViewEvent(props) {
  const { data, closeModal, loading, invalid, pristine, handleSubmit } = props;
  const [definput, setDefinput] = useState({});

  useEffect(() => {
    let event = data && data.item && data.item.id ? data.item : null;
    if (event) {
      setDefinput({ ...definput, ...event });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeName = (e) => {};

  const onFormSubmit = (values) => {};

  return (
    <div className='modal is-active'>
      <div className='modal-background' onClick={closeModal}></div>
      <div className='modal-content'>
        <div className='card'>
          <div className='card-content'>
            <button
              onClick={closeModal}
              className='delete'
              aria-label='close'
              style={{ position: 'absolute', right: 10, top: 10 }}
            ></button>
            <div className='content'>
              <div className='columns'>
                <div className='column'>
                  <div className='level'>
                    <div className='level-left'>
                      <div className='level-item'>
                        <nav
                          className='breadcrumb is-size-6'
                          aria-label='breadcrumbs'
                        >
                          <ul>
                            <Link className='menu-top' to={'/calendar'}>
                              Calendar
                            </Link>
                            <i className='mdi mdi-menu-right' /> Detail
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <form
                    onSubmit={handleSubmit(onFormSubmit)}
                    autoComplete='off'
                    style={{ marginLeft: 32, marginRight: 32 }}
                  >
                    <div className='field is-horizontal'>
                      <div className='field-body'>
                        <Field
                          label='Judul Event'
                          name='subject'
                          type='text'
                          component={TextInput}
                          placeholder='Judul Event'
                          defaultValue={definput.subject}
                          onChange={(e) => onChangeName(e)}
                          readOnly={true}
                        />
                        <Field
                          label='Tipe'
                          name='type'
                          type='text'
                          component={TextInput}
                          placeholder='Tipe'
                          fullwidth={true}
                          defaultValue={definput.type}
                          onChange={(e) => onChangeName(e)}
                          readOnly={true}
                        />
                      </div>
                    </div>
                    {definput.allDayEvent && (
                      <Field
                        label='All Day Event'
                        name='allDayEvent'
                        type='text'
                        component={TextInput}
                        placeholder='All Day Event'
                        fullwidth={true}
                        defaultValue={
                          definput.allDayEvent === true ? 'Yes' : 'No'
                        }
                        onChange={(e) => onChangeName(e)}
                        readOnly={true}
                      />
                    )}
                    {definput.allDayEvent && definput.type === 'event' && (
                      <>
                        <Field
                          label={!definput.endDate ? 'Tanggal' : 'Mulai'}
                          name='dateStart'
                          type='text'
                          component={TextInput}
                          placeholder={!definput.endDate ? 'Tanggal' : 'Mulai'}
                          fullwidth={true}
                          defaultValue={definput.startDate}
                          onChange={(e) => onChangeName(e)}
                          readOnly={true}
                        />
                        {definput.endDate && (
                          <Field
                            label='Berakhir'
                            name='dateEnd'
                            type='text'
                            component={TextInput}
                            placeholder='Berakhir'
                            fullwidth={true}
                            defaultValue={definput.endDate}
                            onChange={(e) => onChangeName(e)}
                            readOnly={true}
                          />
                        )}
                      </>
                    )}
                    {!definput.allDayEvent && (
                      <div className='field is-horizontal'>
                        <div className='field-body'>
                          <Field
                            label='Mulai'
                            name='dateStart'
                            type='text'
                            component={TextInput}
                            placeholder='Mulai'
                            fullwidth={true}
                            defaultValue={
                              definput.startDate + ' ' + definput.startTime
                            }
                            onChange={(e) => onChangeName(e)}
                            readOnly={true}
                          />
                          <Field
                            label='Berakhir'
                            name='dateEnd'
                            type='text'
                            component={TextInput}
                            placeholder='Berakhir'
                            fullwidth={true}
                            defaultValue={
                              definput.endDate + ' ' + definput.endTime
                            }
                            onChange={(e) => onChangeName(e)}
                            readOnly={true}
                          />
                        </div>
                      </div>
                    )}
                    {definput.type === 'libur' && (
                      <div className='field is-horizontal'>
                        <div className='field-body'>
                          <Field
                            label={!definput.endDate ? 'Tanggal' : 'Mulai'}
                            name='dateStart'
                            type='text'
                            component={TextInput}
                            placeholder={
                              !definput.endDate ? 'Tanggal' : 'Mulai'
                            }
                            fullwidth={true}
                            defaultValue={definput.startDate}
                            onChange={(e) => onChangeName(e)}
                            readOnly={true}
                          />
                          {definput.endDate && (
                            <Field
                              label='Berakhir'
                              name='dateEnd'
                              type='text'
                              component={TextInput}
                              placeholder='Berakhir'
                              fullwidth={true}
                              defaultValue={definput.endDate}
                              onChange={(e) => onChangeName(e)}
                              readOnly={true}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    <Field
                      label='Deskripsi'
                      name='description'
                      type='text'
                      component={TextArea}
                      placeholder='Deskripsi'
                      fullwidth={true}
                      readOnly={true}
                    />
                    <div
                      className='field is-grouped'
                      style={{ marginTop: 20, marginBottom: 20 }}
                    >
                      <div className='control'>
                        <button
                          type='submit'
                          disabled={invalid || loading || pristine}
                          className={
                            loading
                              ? 'button is-info is-small is-rounded is-outlined is-loading'
                              : 'button is-info is-small is-rounded is-outlined'
                          }
                        >
                          <i className='fas fa-save icon' />
                        </button>
                      </div>
                      <div className='control'>
                        <button
                          onClick={closeModal}
                          className='button custom-grey is-small is-rounded is-outlined'
                        >
                          <i className='fas fa-arrow-left icon' />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  mapState,
  actions
)(reduxForm({ form: 'calendarViewEventModal', validate })(ModalViewEvent));
