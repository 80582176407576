import {
  CBTQUESTIONS_GET,
  CBTQUESTION_ADD,
  CBTQUESTION_GET,
  CBTQUESTION_EDIT,
  CBTPARTICIPANT_GET,
  CBTPARTICIPANT_CBTQUESTION_DELETE,
  CBTPARTICIPANT_GROUP_GET,
  CBTQUESTION_UPLOADFILE,
  CBTQUESTION_DELETE,
  CBTQUESTION_RESET_SEARCH,
} from './reduxConstant';
import { createReducer } from '../../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    published: 'all',
  },
  items: [],
  uploadFiles: [],
  cbtparticipant: [],
  cbtparticipantGroup: [],
};

const cbtquestionsGet = (state, payload) => {
  const cbtquestions = payload.cbtquestions;
  return {
    ...state,
    total: cbtquestions.total,
    items: [...cbtquestions.items],
    search: { ...cbtquestions.search },
  };
};

const cbtquestionAdd = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) + 1,
    items: [...state.items, payload.cbtquestion],
  };
};

const cbtquestionGet = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.cbtquestion.id + ''),
      payload.cbtquestion,
    ],
  };
};

const cbtquestionEdit = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.cbtquestion.id + ''),
      payload.cbtquestion,
    ],
  };
};

const cbtparticipantGet = (state, payload) => {
  return {
    ...state,
    cbtparticipant: [...payload.cbtparticipants],
  };
};

const cbtparticipantGroupGet = (state, payload) => {
  return {
    ...state,
    cbtparticipantGroup: [...payload.groups],
  };
};

const cbtparticipantCbtquestionDelete = (state, payload) => {
  return {
    ...state,
    cbtparticipant: [
      ...state.cbtparticipant.filter(
        (i) => i.id + '' !== payload.cbtparticipant.id
      ),
    ],
  };
};

const cbtquestionUploadFile = (state, payload) => {
  let uploadFile = payload.uploadFile;
  let uploadFiles =
    state.uploadFiles && state.uploadFiles ? state.uploadFiles : [];
  let filteredUploadFiles = uploadFiles.filter((i) => i.key !== uploadFile.key);
  let newUploadFiles = [...filteredUploadFiles, uploadFile];
  return {
    ...state,
    uploadFiles: newUploadFiles,
  };
};

const cbtquestionDelete = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) - 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.cbtquestion.id + ''),
    ],
  };
};

const cbtquestionResetSearch = (state, payload) => {
  return {
    ...state,
    search: {
      published: 'all',
    },
  };
};

export default createReducer(initialState, {
  [CBTQUESTIONS_GET]: cbtquestionsGet,
  [CBTQUESTION_ADD]: cbtquestionAdd,
  [CBTQUESTION_GET]: cbtquestionGet,
  [CBTQUESTION_EDIT]: cbtquestionEdit,
  [CBTPARTICIPANT_GET]: cbtparticipantGet,
  [CBTPARTICIPANT_CBTQUESTION_DELETE]: cbtparticipantCbtquestionDelete,
  [CBTPARTICIPANT_GROUP_GET]: cbtparticipantGroupGet,
  [CBTQUESTION_UPLOADFILE]: cbtquestionUploadFile,
  [CBTQUESTION_DELETE]: cbtquestionDelete,
  [CBTQUESTION_RESET_SEARCH]: cbtquestionResetSearch,
});
