import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../../app/modals/redux/modalActions';

const actions = {
  closeModal,
};

class ModalInfoAdd extends Component {
  render() {
    const { closeModal } = this.props;
    return (
      <div className='modal is-active'>
        <div className='modal-background' onClick={closeModal}></div>
        <div className='modal-card'>
          <header className='modal-card-head'>
            <div className='modal-card-title has-text-info has-text-weight-normal is-size-4'>
              <i className='mdi mdi-information icon is-size-4' /> Informasi
            </div>
            <button
              onClick={closeModal}
              className='delete'
              aria-label='close'
            ></button>
          </header>
          <section className='modal-card-body'>
            <div className='content pl-2'>
              <h5>Langkah-Langkah:</h5>
              <div className='preline'>
                <ol>
                  <li>Pilih siswa.</li>
                  <li>
                    Jika pesan menggunakan variabel, maka tambahkan [Angka] yang
                    akan digantikan menjadi isian, maka ketikkan pada isi pesan
                    seperti contoh ini:
                    <br />
                    <blockquote>
                      Diberitahukan kepada orang tua [1] untuk segera melunasi
                      SPP bulan [2], sebesar Rp. [3].
                      <br />
                      Harap segera menghubungi admin sekolah untuk detail
                      pembayaran.
                    </blockquote>
                  </li>
                  <li>
                    Klik <span className='tag is-info mb-1'>+ variabel</span>,
                    dan kemudian tambahkan isian sesuai dengan banyaknya
                    variabel yang dibuat di isi pesan, dalam hal ini terdapat 3
                    isian variabel, setiap variabel dipisahkan dengan tanda
                    titik koma ";". <br />
                    <blockquote>
                      Andi
                      <br />
                      Andi;Juli;100.000
                    </blockquote>
                  </li>
                </ol>
                <ol>
                  Kli pada{' '}
                  <span className='tag is-info'>
                    <i className='mdi mdi-file-search icon'></i>
                  </span>{' '}
                  untuk melihat preview pesan, jika preview sudah sesuai klik{' '}
                  <span className='tag is-info'>
                    <i className='mdi mdi-content-save icon'></i>
                  </span>{' '}
                  untuk menyimpan
                </ol>
              </div>
            </div>
          </section>
          <footer className='modal-card-foot'>
            <button
              className='button custom-grey is-small is-rounded is-outlined'
              onClick={closeModal}
            >
              <i className='is-size-6 mdi mdi-arrow-left icon' />
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

export default connect(null, actions)(ModalInfoAdd);
