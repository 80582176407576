import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getCalendarYears,
  getCalendars,
  statusCalendar,
  deleteCalendar,
} from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';

const mapState = (state, ownProps) => {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  let years = [];
  if (state.calendars && state.calendars.items && state.calendars.items[0]) {
    years = state.calendars.years;
  }
  return {
    loading: state.async.loading,
    auth: state.auth,
    scope: scope,
    calendars: state.calendars,
    years: years,
  };
};

const actions = {
  getCalendars,
  getCalendarYears,
  statusCalendar,
  deleteCalendar,
  closeModal,
};

function ModalSetActive(props) {
  const {
    auth,
    history,
    scope,
    calendars,
    years,
    statusCalendar,
    getCalendars,
    getCalendarYears,
    deleteCalendar,
    closeModal,
  } = props;
  const [state, setState] = useState({
    title: 'Kalender',
    itemPerPage: 10,
    currentPage: 1,
    searchText: '',
    isActive: false,
    calendars: {},
  });

  useEffect(() => {
    setState({
      title: 'Kalender',
      itemPerPage: 10,
      currentPage: 1,
      searchText: '',
      isActive: false,
    });
    getCalendars(auth, history);
    getCalendarYears(auth, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setState({
      ...state,
      calendars: calendars,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendars]);

  // Set Active Calendar
  const handleStatusCalendar = (e, value) => {
    e.preventDefault();
    if (value.status) {
      return;
    }
    let newValue = {
      ...value,
      status: !value.status,
    };
    statusCalendar(auth, history, newValue);
  };

  const handleEditCalendar = (calendar) => {
    history.push({
      pathname: '/calendar/edit/' + calendar.id,
      state: { detail: calendar },
    });
    closeModal();
  };

  const handleDeleteCalendar = (calendar) => {
    deleteCalendar(auth, history, calendar.id);
  };

  const List = (props) => {
    const {
      scope,
      items,
      title,
      currentPage,
      itemPerPage,
      onStatus,
      onEdit,
      onDelete,
    } = props;
    return (
      <>
        {items &&
          items.length !== 0 &&
          items.map((item, index) => (
            <ListItems
              key={item.id}
              index={index}
              item={item}
              currentPage={currentPage}
              itemPerPage={itemPerPage}
              scope={scope}
              onStatus={onStatus}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))}
        {items && items.length === 0 && (
          <tr key={0}>
            <td colSpan='7'>
              Tidak ada <span className='is-lowercase'>{title}</span>
            </td>
          </tr>
        )}
      </>
    );
  };

  const ListItems = (props) => {
    const {
      item,
      currentPage,
      itemPerPage,
      index,
      scope,
      onStatus,
      onEdit,
      onDelete,
    } = props;

    const page = (currentPage - 1) * itemPerPage;
    return (
      <tr>
        <td className='has-text-centered'>{page + index + 1}</td>
        <td>{item.title}</td>
        <td className='has-text-centered'>{item.year}</td>
        <td style={{ width: 100 }}>
          <div className='content-wrap flex justify-center'>
            <div>
              {scope.m['calendar_edit'] && (
                <div className='control'>
                  {scope && scope.m && scope.m['calendar_active'] && (
                    <div
                      className={`tags has-addons${
                        item.status === true ? '' : ' hand-pointer'
                      }`}
                      onClick={(e) => onStatus(e, item)}
                    >
                      <span className='tag is-dark'>
                        {item.status ? '' : 'No'}
                      </span>
                      <span className='tag is-info'>
                        {item.status ? 'Aktif' : ''}
                      </span>
                    </div>
                  )}
                  {scope && scope.m && !scope.m['calendar_active'] && (
                    <div className='tags has-addons'>
                      <span className='tag is-dark'>
                        {item.status ? '' : 'No'}
                      </span>
                      <span className='tag is-info'>
                        {item.status ? 'Aktif' : ''}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </td>
        <td className='has-text-centered'>
          <div className='buttons middle flex justify-center'>
            {scope && scope.m && scope.m['calendar_edit'] && (
              <button
                onClick={() => onEdit(item)}
                className='button is-small is-rounded is-info is-outlined mr-2'
              >
                <i className='is-size-6 mdi mdi-pencil icon' />
              </button>
            )}
            {scope && scope.m && scope.m['calendar_delete'] && (
              <button
                disabled={item.status ? true : false}
                onClick={() => onDelete(item)}
                className='button is-small is-rounded is-danger is-outlined mr-2'
              >
                <i className='is-size-6 mdi mdi-delete icon' />
              </button>
            )}
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className='modal is-active'>
      <div className='modal-background' onClick={closeModal}></div>
      <div className='modal-content'>
        <div className='card'>
          <div className='card-content'>
            <button
              onClick={closeModal}
              className='delete'
              aria-label='close'
              style={{ position: 'absolute', right: 10, top: 10 }}
            ></button>
            <div className='content'>
              <div className='columns'>
                <div className='column'>
                  <div className='level'>
                    <div className='level-left'>
                      <div className='level-item'>
                        <nav
                          className='breadcrumb is-size-6'
                          aria-label='breadcrumbs'
                        >
                          <ul>
                            <Link className='menu-top' to={'/calendar'}>
                              Calendar
                            </Link>
                            <i className='mdi mdi-menu-right' /> Set Aktif
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <div className='table-container ml-4 mr-4'>
                    <table
                      id='fadein'
                      className='table is-fullwidth is-hoverable is-narrow'
                    >
                      <thead>
                        <tr>
                          <th className='has-text-centered'>No</th>
                          <th>Judul Kalender</th>
                          <th className='has-text-centered'>Tahun Ajaran</th>
                          <th className='has-text-centered'>Status</th>
                          <th className='has-text-centered'>Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <List
                          auth={auth}
                          scope={scope}
                          items={
                            state && state.calendars && state.calendars.items
                          }
                          years={years}
                          title={state.title}
                          currentPage={state && state.currentPage}
                          itemPerPage={state && state.itemPerPage}
                          onStatus={handleStatusCalendar}
                          onEdit={handleEditCalendar}
                          onDelete={handleDeleteCalendar}
                        />
                        <tr>
                          <td colSpan={3}>
                            <div className='control mt-2'>
                              <div className='tags has-addons'>
                                <span className='tag'>Total</span>
                                <span className='tag is-info'>
                                  {calendars.items.length}
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(connect(mapState, actions)(ModalSetActive));
