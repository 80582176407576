import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editCbtParticipant } from './redux/reduxApi';
import { closeModal } from '../../../../app/modals/redux/modalActions';
import MEditForm from './MEditForm';

const actions = {
  closeModal,
  editCbtParticipant,
};

class ModalEdit extends Component {

  render() {
    const { data, closeModal, editCbtParticipant } = this.props;
    const auth = data.auth;
    const history = data.history;
    const item = data.item;
    return (
      <div className='modal is-active'>
        <div className='modal-background' onClick={closeModal}></div>
        <div className='modal-card'>
          <header className='modal-card-head'>
            <div className='modal-card-title has-text-info has-text-weight-normal'>
              <i className='is-size-4 mdi mdi-account-lock icon mr-2' />
              Edit Peserta CBT
            </div>
            <button
              onClick={closeModal}
              className='delete'
              aria-label='close'
            ></button>
          </header>
          <MEditForm
            auth={auth}
            history={history}
            initialValues={item}
            editCbtParticipant={editCbtParticipant}
            closeModal={closeModal}
          />
        </div>
      </div>
    );
  }
}

export default connect(null, actions)(ModalEdit);
