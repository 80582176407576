import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../../app/common/reduxs/async/asyncActions';
import {
  cbtparticipantsGet,
  cbtparticipantEdit,
  cbtparticipantDelete,
  cbtparticipantResetSearch,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../../app/common/helpers/fetchHelpers';
import { logsFunc } from '../../../../../app/common/helpers/othersHelpers';
import { toastr } from 'react-redux-toastr';

export const getCbtparticipants = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText,
  searchMoreText,
  isReset
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getCbtparticipants' });
    const formData = new FormData();
    if (isReset === 'reset') {
      dispatch(cbtparticipantResetSearch());
    }
    const allDataAccess = auth.authorities.subm.materi_all;
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    formData.append('allData', allDataAccess);
    formData.append('searchMoreText', JSON.stringify(searchMoreText));
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'cbtparticipant/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
        ...searchMoreText,
      };
      dispatch(cbtparticipantsGet({ total, items, search }));
      if (isReset === 'reset') {
        history.push('/cbt/pesertacbt');
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const importCbtparticipant = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'importCbtparticipant' });
    try {
      let logs = logsFunc('create', auth.userid, auth.username, null);
      const formData = new FormData();
      formData.append('participants', JSON.stringify(values.data));
      formData.append('logs', logs);
      const res = await await fetchFunc(
        'add',
        auth,
        history,
        'cbtparticipant/import/',
        formData,
        dispatch
      );
      let duplicate = res.duplicate;
      if (duplicate && duplicate.length > 0) {
        toastr.warning(
          'Duplikasi NIS',
          `Terdapat duplikasi NIS ${duplicate.length}.`
        );
      } else {
        toastr.success('Sukses', `Impor data berhasil.`);
      }
      history.push('/cbt/pesertacbt/');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editCbtParticipant = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editCbtParticipant' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('nis', values.nis);
    formData.append('name', values.name);
    formData.append('uname', values.username);
    formData.append('remarks', values.remarks);
    formData.append('group', values.group);
    formData.append('status', values.status);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'cbtparticipant/edit/' + values.id,
        formData,
        dispatch
      );
      if (!res || !res.cbtparticipant) {
        throw new Error('Terjadi kesalahan...');
      }
      const cbtparticipant = res.cbtparticipant;
      dispatch(cbtparticipantEdit(cbtparticipant));
      toastr.success('Sukses', `Data berhasil tersimpan.`);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const uploadImage = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'uploadImage' });
    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('file', values.file);
    formData.append('filename', values.filename);
    try {
      const res = await fetchFunc(
        'uploadImage',
        auth,
        history,
        'cbtparticipant/upload-image/' + values.id,
        formData,
        dispatch
      );
      const url = res.url;
      dispatch(asyncActionFinish());
      return url;
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteFile = (auth, history, data, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteFile' });
    let logs = logsFunc('edit', auth.userid, auth.username, data.logs);
    const formData = new FormData();
    formData.append('filelink', file.filelink);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'deleteFile',
        auth,
        history,
        'cbtparticipant/delete-file/' + data.id,
        formData,
        dispatch
      );
      const cbtparticipant = res.cbtparticipant;
      dispatch(cbtparticipantEdit(cbtparticipant));
      history.push('/cbt/pesertacbt/edit/' + data.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteCbtparticipant = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteCbtparticipant' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'cbtparticipant/delete/' + id,
        formData,
        dispatch
      );
      const cbtparticipant = res.cbtparticipant;
      dispatch(cbtparticipantDelete(cbtparticipant));
      history.push('/cbt/pesertacbt');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
