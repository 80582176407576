import { checkRes, checkErr } from './checkRes';
import { SITE_ADDRESS, WHATSAPP_ADDRESS } from '../data/siteConfig';
import { fetchWithTimeOut } from './othersHelpers';

const fetchMethod = {
  gets: { method: 'POST', status: 200 },
  add: { method: 'POST', status: 201 },
  get: { method: 'POST', status: 200 },
  edit: { method: 'POST', status: 200 },
  import: { method: 'POST', status: 200 },
  uploadAttach: { method: 'POST', status: 200 },
  uploadImage: { method: 'POST', status: 200 },
  deleteFile: { method: 'POST', status: 200 },
  delete: { method: 'POST', status: 200 },
};

export const fetchFunc = async (
  action,
  auth,
  history,
  url,
  formData,
  dispatch
) => {
  try {
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    let fetchData = await fetchWithTimeOut(SITE_ADDRESS + url, {
      method: fetchMethod[action].method,
      body: formData,
      headers: {
        Authorization: 'Bearer ' + auth.token,
      },
    }, 1000)
    const response = await fetchData.json();
    const checkedRes = checkRes(
      response,
      fetchData,
      dispatch,
      fetchMethod[action].status,
      history
    );
    if (checkedRes) {
      throw checkedRes;
    }
    return response;
  } catch (err) {
    checkErr(err);
  }
};

export const fetchFuncWa = async (
  action,
  auth,
  history,
  url,
  formData,
  dispatch
) => {
  try {
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    let fetchData = await fetchWithTimeOut(WHATSAPP_ADDRESS + url, {
      method: fetchMethod[action].method,
      body: formData,
      headers: {
        Authorization: 'Bearer ' + auth.token,
      },
    }, 1000)
    const response = await fetchData.json();
    const checkedRes = checkRes(
      response,
      fetchData,
      dispatch,
      fetchMethod[action].status,
      history
    );
    if (checkedRes) {
      throw checkedRes;
    }
    return response;
  } catch (err) {
    console.log(err);
  }
};
