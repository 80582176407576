import {
  CALENDARS_GET,
  CALENDAR_ADD,
  CALENDAR_EDIT,
  CALENDAR_GET,
  CALENDAR_STATUS,
  CALENDAR_DELETE,
  CALENDAR_IMPORT,
  CALENDAR_YEARS_GET,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    currentPage: '',
    searchText: '',
    itemPerPage: '',
  },
  items: [],
  years: [],
};

const calendarsGet = (state, payload) => {
  const calendars = payload.calendars;
  return {
    ...state,
    total: calendars.total,
    items: [...calendars.items],
    search: { ...calendars.search },
  };
};

const calendarAdd = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) + 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.calendar.id + ''),
      payload.calendar,
    ],
  };
};

const calendarEdit = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.calendar.id + ''),
      payload.calendar,
    ],
  };
};

const calendarGet = (state, payload) => {
  if (!payload.calendar?.id) return { ...state };
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.calendar.id + ''),
      payload.calendar,
    ],
  };
};

const calendarStatus = (state, payload) => {
  let items = state.items;
  let newItems = [];
  for (let i = 0; i < items.length; i++) {
    let item = items[i];
    if (item.status === true) {
      item = { ...item, status: false };
      newItems = [...newItems, item];
    } else if (item.id === payload.calendar.id) {
      newItems = [...newItems, payload.calendar];
    } else {
      newItems = [...newItems, item];
    }
  }
  return {
    ...state,
    items: [...newItems],
  };
};

const calendarDelete = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) - 1,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.calendar.id + ''),
    ],
  };
};

const calendarImport = (state, payload) => {
  const calendars = payload.calendars;
  return {
    ...state,
    total: calendars.total,
    items: [...calendars.items],
    search: { ...calendars.search },
  };
};

const calendarYearsGet = (state, payload) => {
  const years = payload.years;
  return {
    ...state,
    years: [...years.years],
  };
};

export default createReducer(initialState, {
  [CALENDARS_GET]: calendarsGet,
  [CALENDAR_ADD]: calendarAdd,
  [CALENDAR_EDIT]: calendarEdit,
  [CALENDAR_GET]: calendarGet,
  [CALENDAR_STATUS]: calendarStatus,
  [CALENDAR_DELETE]: calendarDelete,
  [CALENDAR_IMPORT]: calendarImport,
  [CALENDAR_YEARS_GET]: calendarYearsGet,
});
