import React from 'react';

const DetailTitle = ({ title }) => {
  return (
    <div className='level-left'>
      <div className='level-item is-size-6 has-text-weight-semibold'>
        {title}
      </div>
    </div>
  );
};

export default DetailTitle