import {
  CBTPARTICIPANTS_GET,
  CBTPARTICIPANT_EDIT,
  CBTPARTICIPANT_UPLOADFILE,
  CBTPARTICIPANT_DELETE,
  CBTPARTICIPANT_RESET_SEARCH,
} from './reduxConstant';

export const cbtparticipantsGet = (cbtparticipants) => {
  return {
    type: CBTPARTICIPANTS_GET,
    payload: {
      cbtparticipants,
    },
  };
};

export const cbtparticipantEdit = (cbtparticipant) => {
  return {
    type: CBTPARTICIPANT_EDIT,
    payload: {
      cbtparticipant,
    },
  };
};

export const cbtparticipantUploadFile = (status) => {
  return {
    type: CBTPARTICIPANT_UPLOADFILE,
    payload: {
      status,
    },
  };
};

export const cbtparticipantDelete = (cbtparticipant) => {
  return {
    type: CBTPARTICIPANT_DELETE,
    payload: {
      cbtparticipant,
    },
  };
};

export const cbtparticipantResetSearch = () => {
  return {
    type: CBTPARTICIPANT_RESET_SEARCH,
    payload: {},
  };
};
