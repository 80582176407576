import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoadingButton from '../../../../app/common/layout/LoadingButton';
import MViewSummaryAnswerFormList from './MViewSummaryAnswerFormList';
import { stringSimilarity } from 'string-similarity-js';
import { sortArrayObject } from '../../../../app/common/helpers/objectHelpers';

const TextInput = ({
  name,
  type,
  onChange,
  onKeyDown,
  disabled,
  readOnly,
  defaultValue,
  placeholder,
  label,
  style,
}) => (
  <div className='field'>
    {label && label !== 'noLabel' && (
      <label className='label' htmlFor={name}>
        {label}
      </label>
    )}
    <div className='control'>
      <input
        className='input is-size-6'
        id={name}
        readOnly={readOnly}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={defaultValue}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        style={{ ...style }}
      />
    </div>
  </div>
);

function ViewSummaryAnswerForm(props) {
  const {
    auth,
    history,
    loading,
    scope,
    closeModal,
    id,
    // initialValues,
    studentDetail,
    questions,
    keyAnswers,
    answers,
    scores,
    index,
    finalScore,
    detailScore,
    submitScores,
  } = props;
  const [state, setState] = useState([]);
  const [totalscr, setTotalscr] = useState({ total: 0, sc: 0, dv: 0 });
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    let getAutoScore = [];
    let qLength = questions && questions.length;
    let getScore;
    let getTotalScore;
    let roundScore;
    answers &&
      answers.forEach((ans, index) => {
        let keyAns =
          keyAnswers &&
          keyAnswers[index] &&
          keyAnswers[index].answer &&
          keyAnswers[index].answer.toString().trim() + '';
        let stAns = ans && ans.answer && ans.answer.trim() + '';
        if (detailScore) {
          getAutoScore = [...detailScore];
        } else {
          let getScore = handleCountScore(keyAns, stAns, index);
          let obj = {
            no: ans.no,
            key: ans.key,
            keyAns: keyAns,
            stAns: stAns,
            score: getScore,
            finalScore: getScore,
            isCorrect: getScore === 1 ? true : false,
          };
          getAutoScore = [...getAutoScore, obj];
        }
      });
    if (finalScore) {
      getTotalScore = (parseFloat(finalScore) * qLength) / 100;
      roundScore = finalScore;
    } else {
      getTotalScore = getAutoScore.reduce(
        (n, { finalScore }) => n + finalScore,
        0
      );
      getScore = (getTotalScore / qLength) * 100;
      roundScore = Math.round((getScore + Number.EPSILON) * 100) / 100;
    }
    setTotalscr({ total: roundScore, sc: getTotalScore, dv: qLength });
    setState([...getAutoScore]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.onafterprint = function() {
      window.location.reload();
    }
  }, [])
  

  const convertScore = (scoreCount, val) => {
    if (scoreCount === null) {
      return 'calculate';
    } else if (scoreCount === 0) {
      return 0;
    } else if (scoreCount > 0) {
      let rounder = [10, 100, 1000];
      let roundScore =
        Math.round((scoreCount + Number.EPSILON) * rounder[val - 1]) /
        rounder[val - 1];
      return parseFloat(roundScore);
    }
  };

  const handleCountScore = (keyAns, stAns, index) => {
    let score = 0;
    let question = questions && questions[index];
    let questionType = question && question.questionType;
    if (questionType === 'optionQuestion') {
      const string_chop = function (str, size) {
        if (str == null) return [];
        str = String(str);
        size = ~~size;
        return size > 0
          ? str.match(new RegExp('.{1,' + size + '}', 'g'))
          : [str];
      };
      let firstArray = stAns;
      if (stAns.length > keyAns.length) {
        firstArray = string_chop(stAns, keyAns.length)[0];
      }
      if (firstArray === keyAns) {
        score = 1;
      }
    }

    if (questionType === 'longQuestion') {
      let scr = stringSimilarity(keyAns, stAns);
      score = convertScore(scr, 2);
    }

    if (questionType === 'shortQuestion') {
      if (keyAns === stAns) {
        score = 1;
      }
    }
    return score;
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setIsEdit(!isEdit);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let filteredScores = [];
    if (scores && scores[0]) {
      filteredScores = scores.filter((i) => i.id !== studentDetail.id);
    }
    let storeScore = {
      id: studentDetail.id,
      nis: studentDetail.nis,
      detailScore: state,
      finalScore: totalscr.total,
      cbtquestionId: id,
    };
    if (filteredScores && filteredScores[0]) {
      filteredScores = sortArrayObject(
        [...filteredScores, storeScore],
        'nis',
        'asc'
      );
    } else {
      filteredScores = [storeScore];
    }
    setIsEdit(!isEdit);
    submitScores(auth, history, id, filteredScores);
  };

  const handleEnter = (e) => {
    let inputId = e.target.id;
    let no = inputId.split('_')[0];
    let arrowUp = 'ArrowUp';
    let arrowDown = 'ArrowDown';
    let index;
    let el;
    answers &&
      answers.find((i, idx) => {
        index = idx;
        return i.no + '' === no + '';
      });
    if (e.key.toLowerCase() === 'enter' || e.key === arrowDown) {
      let stateLength = answers && answers.length;
      if (index + 1 > stateLength - 1) {
        return;
      }
      el = document.getElementById(answers[index + 1].no + '_score');
      if (el) {
        el.focus();
        el.select();
      }
    }
    if (e.key === arrowUp) {
      if (index - 1 < 0) {
        return;
      }
      el = document.getElementById(answers[index - 1].no + '_score');
      if (el) {
        el.focus();
        el.select();
      }
    }
  };

  const onChangeInput = (e, values) => {
    e.preventDefault();
    let val = e.target.value;
    if (
      val.length > 1 &&
      val.charAt(0) + '' === '0' &&
      val.charAt(1) + '' !== '.'
    ) {
      val = val.charAt(1);
    }
    if (isNaN(val)) {
      return;
    }
    if (val.length < 1) {
      val = 0;
    }
    if (val > 1) {
      return;
    }
    let filterState =
      state && state.filter((i) => i.key + '' !== values.key + '');
    let prevItem = state && state.find((i) => i.key + '' === values.key + '');
    let newItem = { ...prevItem, finalScore: val };
    let newState = [...filterState, newItem];

    let getScore = newState.reduce(
      (n, { finalScore }) => n + parseFloat(finalScore),
      0
    );
    let getRoundScore = Math.round((getScore + Number.EPSILON) * 100) / 100;
    let qLength = questions && questions.length;
    let getTotalScore = (getRoundScore / qLength) * 100;
    let roundTotalScore =
      Math.round((getTotalScore + Number.EPSILON) * 100) / 100;
    setTotalscr({ total: roundTotalScore, sc: getRoundScore, dv: qLength });

    setState(sortArrayObject(newState, 'no', 'asc'));
  };

  const handleLink = () => {
    let printContents = document.getElementById('summary-jawaban').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    document.title = `Summary Jawaban ${studentDetail?.nis} -${' '}
    ${studentDetail?.username}`;
    window.print();
    document.body.innerHTML = originalContents;
  };

  return (
    <div id='summary-jawaban'>
      <div>
        <div className='sticky has-background-white py-2' style={{ top: -20 }}>
          <div className='level'>
            <div className='level-left'>
              <div className='level-item'>
                <nav className='breadcrumb is-size-6' aria-label='breadcrumbs'>
                  <ul>
                    <li className='is-active has-text-weight-bold'>
                      <Link to={'/cbt/soalcbt/penilaian/' + id}>
                        {studentDetail?.no ?? index + 1}. {studentDetail?.nis} -{' '}
                        {studentDetail?.username}
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            <div className='level-right'>
              <div className='level-item'>
                <div className='buttons'>
                  {!isEdit && (
                    <>
                      <button
                        onClick={(e) => handleLink(e)}
                        className='button is-small is-rounded is-info is-outlined ml-1'
                      >
                        <i className='is-size-6 mdi mdi-file-excel icon' />
                      </button>
                      <button
                        onClick={(e) => handleEdit(e)}
                        className='button is-small is-rounded is-info is-outlined ml-1'
                      >
                        <i className='is-size-6 mdi mdi-pencil icon' />
                      </button>
                      <button
                        onClick={closeModal}
                        className='button custom-grey is-small is-rounded is-outlined'
                      >
                        <i className='is-size-6 mdi mdi-arrow-left icon' />
                      </button>
                    </>
                  )}
                  {isEdit && (
                    <>
                      {scope &&
                        scope.subm &&
                        scope.subm['cbtquestion_score'] && (
                          <>
                            <button
                              onClick={(e) => handleSubmit(e)}
                              className='button is-small is-rounded is-info is-outlined ml-1'
                            >
                              <i className='is-size-6 mdi mdi-content-save icon' />
                            </button>
                            <button
                              onClick={(e) => handleEdit(e)}
                              className='button is-small is-rounded is-info is-outlined ml-1'
                            >
                              <i className='is-size-6 mdi mdi-arrow-left icon' />
                            </button>
                          </>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='columns'>
          <div className='column is-third-quarter'>
            <div className='content'>
              <table className='table is-fullwidth is-hoverable is-narrow'>
                <thead>
                  <tr>
                    <th className='has-text-centered'>No</th>
                    <th className='has-text-centered'>Pertanyaan</th>
                    <th className='has-text-centered'>Jawaban Siswa</th>
                    <th className='has-text-centered' style={{ width: 90 }}>
                      Nilai
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading === true ? (
                    <tr>
                      <td>
                        <LoadingButton />
                      </td>
                    </tr>
                  ) : (
                    <MViewSummaryAnswerFormList
                      auth={auth}
                      scope={scope}
                      state={state}
                      questions={questions}
                      onChangeInput={onChangeInput}
                      handleEnter={handleEnter}
                      isEdit={isEdit}
                    />
                  )}
                  <tr>
                    <td
                      className='has-text-centered middle has-text-weight-semibold'
                      colSpan={3}
                    >
                      Jumlah Nilai (
                      {totalscr?.sc ? Math.round(totalscr?.sc * 100) / 100 : 0}{' '}
                      / {totalscr?.dv} x 100)
                    </td>
                    <td
                      colSpan={3}
                      className={`middle${isEdit ? '' : ' has-text-right'}`}
                    >
                      {!isEdit && totalscr?.total}
                      {isEdit && (
                        <TextInput
                          readOnly={true}
                          name='totalScore'
                          style={{ textAlign: 'right', minWidth: 90 }}
                          defaultValue={totalscr?.total ?? 0}
                          placeholder='Jumlah Nilai'
                          label='noLabel'
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewSummaryAnswerForm;
