import { mdiInformation, mdiShieldHalfFull } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';

function Modal({ children, closeModal, width, variant, label }) {
  return (
    <div className='modal is-active' style={{ zIndex: 999 }}>
      <div className='modal-background' onClick={closeModal}></div>
      <div
        className='modal-content flex justify-center'
        style={{ overflow: 'hidden', maxWidth: 600 }}
      >
        <div style={{ overflow: 'hidden' }}>
          <div className='card'>
            <div
              className='flex justify-end pt-2 pr-2'
              style={{
                overflow: 'hidden',
              }}
            >
              <button
                onClick={closeModal}
                className='delete'
                aria-label='close'
              ></button>
            </div>
            <div
              className='card-content'
              style={{ padding: '0px 0px 20px 0px' }}
            >
              <div className='px-5'>
                {variant && (
                  <>
                    {(!label || (label && label !== 'noLabel')) && (
                      <p
                        className={`has-text-${variant} flex justify-start align-center is-size-5`}
                      >
                        <span className='icon is-medium'>
                          <Icon
                            path={label ? mdiInformation : mdiShieldHalfFull}
                            size={1}
                          />
                        </span>
                        {label ? label : 'Konfirmasi'}
                      </p>
                    )}
                  </>
                )}
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
