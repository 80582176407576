import React, { useState } from 'react';
import { closeModal } from '../../../app/modals/redux/modalActions';
import { connect } from 'react-redux';
import PROFILE from '../../../pictures/profile.png';
import { editRemarksMonitoring } from './redux/reduxApi';
import { format, parseISO } from 'date-fns';
import { toastr } from 'react-redux-toastr';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  return {
    scope: scope,
  };
}

const actions = {
  editRemarksMonitoring,
  closeModal,
};

const TextArea = ({
  name,
  type,
  onChange,
  onFocus,
  disabled,
  readOnly,
  defaultValue,
  placeholder,
  label,
  style,
}) => (
  <div className='field'>
    {label && label !== 'noLabel' && (
      <label className='label' htmlFor={name}>
        {label}
      </label>
    )}
    <div className='control'>
      <textarea
        className='textarea'
        id={name}
        readOnly={readOnly}
        onChange={onChange}
        onFocus={onFocus}
        value={defaultValue}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        style={{ ...style }}
        rows={6}
      />
    </div>
  </div>
);

function ModalNoteView({ closeModal, editRemarksMonitoring, data }) {
  const { auth, history, id, tes, student, note } = data;
  const [input, setInput] = useState(note ? note.note : '');
  const [editState, setEditState] = useState(false);

  const onChangeInput = (e, student) => {
    e.preventDefault();
    let val = e.target.value;
    setInput(val);
  };

  const handleSave = () => {
    let remarks = tes?.remarks ? JSON.parse(tes.remarks) : {};
    let notes = remarks?.notes ? remarks.notes : [];
    let newNote = {
      nis: student.nis,
      studentName: student.studentName,
      note: input,
      createdAt: new Date(),
      createdBy: auth.username,
    };
    if (notes && notes[0]) {
      notes = [...notes.filter((n) => n.nis !== student.nis), newNote];
    } else {
      notes.push(newNote);
    }
    let updateRemarks = { ...remarks, notes };
    console.log(updateRemarks);
    let newTes = { ...tes, remarks: JSON.stringify(updateRemarks) };
    editRemarksMonitoring(auth, history, newTes, id);
    toastr.success('Info', 'Note disimpan');
    closeModal();
  };

  return (
    <div className='modal is-active'>
      <div className='modal-background' onClick={closeModal}></div>
      <div
        className='modal-content flex justify-center'
        style={{ overflow: 'hidden' }}
      >
        <div className='' style={{ width: 600 }}>
          <div className='' style={{ overflow: 'hidden' }}>
            <div className='card'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: 8,
                  paddingRight: 8,
                  overflow: 'hidden',
                }}
              >
                <button
                  onClick={closeModal}
                  className='delete'
                  aria-label='close'
                ></button>
              </div>
              <div
                className='card-content'
                style={{ padding: '0px 17px 20px 15px' }}
              >
                <div className='media' style={{ alignItems: 'center' }}>
                  <div className='media-left'>
                    <figure className='image is-48x48'>
                      <img
                        src={PROFILE}
                        alt='profile'
                        style={{ height: 48, width: 48 }}
                      />
                    </figure>
                  </div>
                  <div className='media-content'>
                    <p className='has-text-grey-dark has-text-weight-semibold is-size-6'>
                      {student.siswa}
                    </p>
                    <p className='has-text-grey is-size-7'>{student.nis}</p>
                  </div>
                </div>
                <div className='content' style={{ marginTop: -8 }}>
                  {!editState && note && (
                    <table>
                      <tbody>
                        <tr>
                          <td><span className='has-text-success' style={{marginLeft: -5}}><i className='mdi mdi-note-edit icon'></i>Note</span></td>
                        </tr>
                        <tr>
                          <td className='preline'>{input}</td>
                        </tr>
                        <tr>
                          <td className='is-italic is-size-7 has-text-right'>
                            Dibuat:{' '}
                            <span className='has-text-weight-medium'>
                              {(note &&
                                format(
                                  parseISO(note.createdAt),
                                  'd LLLL yyyy - HH:mm:ss'
                                )) ||
                                '-'}{' '}
                              oleh
                            </span>{' '}
                            <span className='is-capitalized has-text-weight-medium'>
                              {(note && note.createdBy) || '-'}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  {!note && (
                    <>
                      <div className='mb-2'><span className='has-text-success ml-1'><i className='mdi mdi-note-plus icon'></i>Note</span></div>
                      <TextArea
                        name='note'
                        defaultValue={input ?? ''}
                        onChange={(e) => onChangeInput(e, student)}
                        placeholder='Isi note'
                        label='noLabel'
                      />
                    </>
                  )}
                  {editState && note && (
                    <>
                      <div className='mb-2'><span className='has-text-success ml-1'><i className='mdi mdi-note-edit icon'></i>Note</span></div>
                      <TextArea
                        name='note'
                        defaultValue={input ?? ''}
                        onChange={(e) => onChangeInput(e, student)}
                        placeholder='Isi note'
                        label='noLabel'
                      />
                    </>
                  )}
                  <div className='has-text-grey-dark is-size-7 flex justify-end mt-2'>
                    <div className='mb-2 is-italic'></div>
                    {note && !editState && (
                      <button
                        onClick={() => setEditState(true)}
                        className='button is-small is-rounded is-outlined is-info'
                      >
                        <i className='is-size-6 mdi mdi-pencil icon' />
                      </button>
                    )}
                    {editState && (
                      <>
                        <button
                          onClick={() => setEditState(false)}
                          className='button is-small is-rounded is-outlined custom-grey mr-2'
                        >
                          <i className='is-size-6 mdi mdi-arrow-left-thin icon' />
                        </button>
                        <button
                          onClick={() => handleSave()}
                          className='button is-small is-rounded is-outlined is-info'
                        >
                          <i className='is-size-6 mdi mdi-content-save icon' />
                        </button>
                      </>
                    )}
                    {!note && (
                      <button
                        onClick={() => handleSave()}
                        className='button is-small is-rounded is-outlined is-info'
                      >
                        <i className='is-size-6 mdi mdi-content-save icon' />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapState, actions)(ModalNoteView);
