import React from 'react';

const LoginTextInput = ({
  input,
  width,
  type,
  icon,
  disabled,
  readOnly,
  placeholder,
  label,
  meta: { touched, error, active },
}) => {
  return (
    <div className='field'>
      <label className='label is-size-7'>{label}</label>
      <div className='control has-icons-left'>
        <input
          {...input}
          className={
            touched && !!error
              ? 'input is-danger is-small is-rounded'
              : 'input is-small is-rounded'
          }
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
        />
        <span className='icon is-small is-left'>
          {icon === 'account' && (
            <svg width={12} height={12} fill={active ? '#000000' : '#c2c2c2'} viewBox='0 0 24 24'>
              <path d='M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z' />
            </svg>
          )}
          {icon === 'lock' && (
            <svg width={12} height={12} fill={active ? '#000000' : '#c2c2c2'} viewBox='0 0 24 24'>
              <path d='M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z' />
            </svg>
          )}
        </span>
      </div>
      {touched && error && <p className='help is-danger'>{error}</p>}
    </div>
  );
};

export default LoginTextInput;
