import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  sendListTesScores,
  getDetailTesScores,
  getListTesScores,
} from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';
import MSyncScoreForm from './MSyncScoreForm';
import { uniqueValueObject } from '../../../app/common/helpers/objectHelpers';

const mapState = (state, ownProps) => {
  return {
    detailScores: state.tess.detailScores,
    listScores: state.tess.listScores,
    studentAnswer: state.tess.studentAnswer,
  };
};

const actions = {
  closeModal,
  getDetailTesScores,
  getListTesScores,
  sendListTesScores,
};

function ModalSyncScore(props) {
  const {
    data,
    closeModal,
    detailScores,
    listScores,
    studentAnswer,
    getDetailTesScores,
    getListTesScores,
    sendListTesScores,
  } = props;
  const { auth, history, id, item } = data;
  const scores = item?.scores;

  let uniqueKelas = uniqueValueObject(studentAnswer, 'kelas');
  let optionKelas = [];
  for (let i = 0; i < uniqueKelas.length; i++) {
    const item = uniqueKelas[i];
    let newItem = {
      key: item,
      text: item,
      value: item,
    };
    optionKelas = [...optionKelas, newItem];
  }

  useEffect(() => {
    let newValues = {
      ...item,
      kelas: uniqueKelas[0],
    };
    getDetailTesScores(auth, history, id, newValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ModalBig closeModal={closeModal}>
      <ModalHeader
        title='Sync Nilai'
        color='info'
        icon='file-sync'
        closeModal={closeModal}
      />
      <MSyncScoreForm
        auth={auth}
        history={history}
        id={id}
        item={item}
        scores={scores}
        detailScores={detailScores}
        listScores={listScores}
        getDetailTesScores={getDetailTesScores}
        getListTesScores={getListTesScores}
        optionKelas={optionKelas}
        sendListTesScores={sendListTesScores}
        closeModal={closeModal}
      />
      <ModalFooter closeModal={closeModal} />
    </ModalBig>
  );
}

function ModalHeader({ title, color, icon, closeModal }) {
  return (
    <header className={`modal-card-head has-background-${color}`}>
      <div className='modal-card-title has-text-white has-text-weight-normal'>
        <i className={`is-size-4 mdi mdi-${icon ? icon : 'shield'} icon`} />{' '}
        {title}
      </div>
      <button
        onClick={closeModal}
        className='delete'
        aria-label='close'
      ></button>
    </header>
  );
}

function ModalFooter({ closeModal }) {
  return (
    <footer className='modal-card-foot'>
      <button
        className='button custom-grey is-small is-rounded is-outlined'
        onClick={closeModal}
      >
        <i className='is-size-6 mdi mdi-arrow-left icon' />
      </button>
    </footer>
  );
}

function ModalBig({ children, closeModal }) {
  return (
    <div className='modal is-active'>
      <div className='modal-background' onClick={closeModal}></div>
      <div className='modal-card'>{children}</div>
    </div>
  );
}

export default connect(mapState, actions)(ModalSyncScore);
