import React, { useState, useEffect } from 'react';
import LoadingButton from '../../../app/common/layout/LoadingButton';
import MViewSummaryAnswerFormList from './MViewSummaryAnswerFormList';
import { stringSimilarity } from 'string-similarity-js';
import { sortArrayObject } from '../../../app/common/helpers/objectHelpers';
import ReactTooltip from 'react-tooltip';
import { roundNumber } from '../../../app/common/helpers/othersHelpers';
import DetailTitle from '../../../app/common/layout/DetailTitle';

function MViewSummaryAnswerForm(props) {
  const {
    auth,
    history,
    loading,
    scope,
    closeModal,
    id,
    studentDetail,
    questions,
    keyAnswers,
    answers,
    scores,
    optScore,
    finalScore,
    detailScore,
    submitTugasScores,
  } = props;
  const [state, setState] = useState([]);
  const [totalscr, setTotalscr] = useState({ total: 0, sc: 0, dv: 0 });
  const [isEdit, setIsEdit] = useState(false);
  let qDevider = 0;
  for (let i = 0; i < questions.length; i++) {
    let question = questions[i];
    qDevider = optScore && qDevider + 1 * optScore[question.questionType];
  }

  useEffect(() => {
    const handleEvent = () => {
      window.location.reload();
    };
    const element = window;
    element.addEventListener('afterprint', handleEvent);
    return () => {
      element.removeEventListener('afterprint', handleEvent);
    };
  }, []);

  function initScore() {
    let getAutoScore = [];
    let getScore;
    let getTotalScore;
    let roundScore;
    answers &&
      answers.forEach((ans, index) => {
        let kA =
          keyAnswers &&
          keyAnswers[0] &&
          keyAnswers.find((ka) => ka.key === ans.key)?.answer + '';
        let keyAns = kA && kA.trim() + '';
        let sA = ans && ans.answer && ans.answer + '';
        let stAns = sA && sA.trim() + '';
        if (detailScore) {
          getAutoScore = [...detailScore];
        } else {
          let { score, isCorrect } = handleCountScore(keyAns, stAns, index);
          let obj = {
            no: ans.no,
            key: ans.key,
            keyAns: keyAns,
            stAns: stAns,
            score: score,
            finalScore: score,
            isCorrect: isCorrect,
          };
          getAutoScore = [...getAutoScore, obj];
        }
      });
    if (finalScore) {
      getTotalScore = (parseFloat(finalScore) * qDevider) / 100;
      roundScore = finalScore;
    } else {
      getTotalScore = getAutoScore.reduce(
        (n, { finalScore }) => n + finalScore,
        0
      );
      getScore = (getTotalScore / qDevider) * 100;
      roundScore = roundNumber(getScore);
    }
    setTotalscr({ total: roundScore, sc: getTotalScore, dv: qDevider });
    setState([...getAutoScore]);
  }

  useEffect(() => {
    initScore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convertScore = (scoreCount, val) => {
    if (scoreCount === null) {
      return 'calculate';
    } else if (scoreCount === 0) {
      return 0;
    } else if (scoreCount > 0) {
      let rounder = [10, 100, 1000];
      let roundScore =
        Math.round((scoreCount + Number.EPSILON) * rounder[val - 1]) /
        rounder[val - 1];
      return parseFloat(roundScore);
    }
  };

  const handleCountScore = (keyAns, stAns, index) => {
    let score = 0;
    let isCorrect = false;
    let question = questions && questions[index];
    let questionType = question && question.questionType;
    if (
      question &&
      (questionType === 'optionQuestion' ||
        questionType === 'rightWrongQuestion')
    ) {
      const string_chop = function (str, size) {
        if (str == null) return [];
        str = String(str);
        size = ~~size;
        return size > 0
          ? str.match(new RegExp('.{1,' + size + '}', 'g'))
          : [str];
      };
      let firstArray = stAns;
      if (stAns.length > keyAns.length) {
        firstArray = string_chop(stAns, keyAns.length)[0];
      }
      if (firstArray === keyAns) {
        score = optScore && 1 * optScore[questionType];
        isCorrect = score === optScore[questionType] ? true : false;
      }
    }

    if (questionType === 'longQuestion') {
      let scr = stringSimilarity(keyAns, stAns);
      score = optScore && convertScore(scr, 2) * optScore[questionType];
      isCorrect = score === optScore[questionType] ? true : false;
    }

    if (questionType === 'shortQuestion') {
      let lowerCaseKeyAns =
        keyAns.length > 0 ? (keyAns + '').toLowerCase() : '';
      let lowerCaseStAns = stAns.length > 0 ? (stAns + '').toLowerCase() : '';

      if (lowerCaseKeyAns === lowerCaseStAns) {
        score = optScore && 1 * optScore[questionType];
        isCorrect = score === optScore[questionType] ? true : false;
      }
    }
    return { isCorrect, score };
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setIsEdit(!isEdit);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let filteredScores = scores.filter(
      (i) => i.studentTugasId !== studentDetail.id
    );
    let newStudentScore = {
      tugasId: id,
      studentTugasId: studentDetail.id,
      nis: studentDetail.nis,
      detailScore: state,
      finalScore: totalscr.total,
    };
    let newScores = sortArrayObject(
      [...filteredScores, newStudentScore],
      'nis',
      'asc'
    );
    submitTugasScores(auth, history, id, newScores);
    setIsEdit(!isEdit);
  };

  const handleSubmitClose = (e) => {
    e.preventDefault();
    let filteredScores = scores.filter(
      (i) => i.studentTugasId !== studentDetail.id
    );
    let newStudentScore = {
      tugasId: id,
      studentTugasId: studentDetail.id,
      nis: studentDetail.nis,
      detailScore: state,
      finalScore: totalscr.total,
    };
    let newScores = sortArrayObject(
      [...filteredScores, newStudentScore],
      'nis',
      'asc'
    );
    submitTugasScores(auth, history, id, newScores);
    closeModal();
  };

  const handleEnter = (e) => {
    let inputId = e.target.id;
    let no = inputId.split('_')[0];
    let arrowUp = 'ArrowUp';
    let arrowDown = 'ArrowDown';
    let index;
    let el;
    answers &&
      answers.find((i, idx) => {
        index = idx;
        return i.no + '' === no + '';
      });
    if (e.key.toLowerCase() === 'enter' || e.key === arrowDown) {
      let stateLength = answers && answers.length;
      if (index + 1 > stateLength - 1) {
        return;
      }
      el = document.getElementById(answers[index + 1].no + '_score');
      if (el) {
        el.focus();
        el.select();
      }
    }
    if (e.key === arrowUp) {
      if (index - 1 < 0) {
        return;
      }
      el = document.getElementById(answers[index - 1].no + '_score');
      if (el) {
        el.focus();
        el.select();
      }
    }
  };

  const onChangeInput = (e, values, questionType) => {
    e.preventDefault();
    let val = e.target.value;
    let limitNumber = optScore[questionType];
    if (
      val.length > 1 &&
      val.charAt(0) + '' === '0' &&
      val.charAt(1) + '' !== '.'
    ) {
      val = val.charAt(1);
    }
    if (isNaN(val)) {
      return;
    }
    if (val.length < 1) {
      val = 0;
    }
    if (val > limitNumber) {
      return;
    }
    let filterState =
      state && state.filter((i) => i.key + '' !== values.key + '');
    let prevItem = state && state.find((i) => i.key + '' === values.key + '');
    let newItem = { ...prevItem, finalScore: val };
    let newState = [...filterState, newItem];

    let getScore = newState.reduce(
      (n, { finalScore }) => n + parseFloat(finalScore),
      0
    );
    let getRoundScore = Math.round((getScore + Number.EPSILON) * 100) / 100;
    let getTotalScore = (getRoundScore / qDevider) * 100;
    let roundTotalScore =
      Math.round((getTotalScore + Number.EPSILON) * 100) / 100;
    setTotalscr({ total: roundTotalScore, sc: getRoundScore, dv: qDevider });

    setState(sortArrayObject(newState, 'no', 'asc'));
  };
  // Print PDF
  const handlePrintPDF = () => {
    let divContent = document.getElementById('print-page');
    let printContents = divContent.innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    document.title = `Jawaban ${studentDetail?.nis} - ${studentDetail?.siswa}`;
    window.print();
    document.body.innerHTML = originalContents;
  };

  return (
    <div id='print-page'>
      <div>
        <div className='sticky has-background-white py-2' style={{ top: -20 }}>
          <div className='level'>
            <DetailTitle
              title={`${studentDetail?.no} . ${studentDetail?.nis} . ${studentDetail?.siswa}`}
            />

            <div className='level-right'>
              <div className='level-item'>
                <div className='buttons'>
                  <button
                    onClick={(e) => handlePrintPDF(e)}
                    className='button is-small is-rounded is-info is-outlined ml-1'
                  >
                    <i className='is-size-6 mdi mdi-file-pdf-box icon' />
                  </button>
                  {!isEdit && (
                    <>
                      <div data-for='main' data-tip='Simpan & Tutup'>
                        <button
                          onClick={(e) => handleSubmitClose(e)}
                          className='button is-small is-rounded is-info is-outlined ml-1'
                        >
                          <i className='is-size-6 mdi mdi-content-save-move icon' />
                        </button>
                        <ReactTooltip
                          id='main'
                          place='bottom'
                          type='dark'
                          effect='solid'
                          multiline={true}
                        />
                      </div>
                      <button
                        onClick={(e) => handleEdit(e)}
                        className='button is-small is-rounded is-info is-outlined ml-1'
                      >
                        <i className='is-size-6 mdi mdi-pencil icon' />
                      </button>
                      <button
                        onClick={closeModal}
                        className='button custom-grey is-small is-rounded is-outlined'
                      >
                        <i className='is-size-6 mdi mdi-arrow-left icon' />
                      </button>
                    </>
                  )}
                  {isEdit && (
                    <>
                      {scope && scope.subm && scope.subm['tugas_score'] && (
                        <>
                          <button
                            onClick={(e) => handleSubmit(e)}
                            className='button is-small is-rounded is-info is-outlined ml-1'
                          >
                            <i className='is-size-6 mdi mdi-content-save icon' />
                          </button>
                          <button
                            onClick={(e) => {
                              initScore();
                              handleEdit(e);
                            }}
                            className='button is-small is-rounded is-info is-outlined ml-1'
                          >
                            <i className='is-size-6 mdi mdi-arrow-left icon' />
                          </button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='columns'>
          <div className='column is-third-quarter'>
            <div className='content'>
              <div className='c-table'>
                <table
                  className='table is-fullwidth is-bordered is-hoverable is-narrow'
                  style={{ width: '100%' }}
                >
                  <thead>
                    <tr>
                      <th className='has-text-centered' style={{ width: '5%' }}>
                        No
                      </th>
                      <th
                        className='has-text-centered'
                        style={{ width: '54%' }}
                      >
                        Pertanyaan
                      </th>
                      <th
                        className='has-text-centered'
                        style={{ width: '35%' }}
                      >
                        Jawaban Siswa
                      </th>
                      <th className='has-text-centered' style={{ width: '7%' }}>
                        Nilai
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading === true ? (
                      <tr>
                        <td>
                          <LoadingButton />
                        </td>
                      </tr>
                    ) : (
                      <MViewSummaryAnswerFormList
                        auth={auth}
                        scope={scope}
                        state={state}
                        questions={questions}
                        onChangeInput={onChangeInput}
                        handleEnter={handleEnter}
                        isEdit={isEdit}
                        roundNumber={roundNumber}
                      />
                    )}
                    <tr>
                      <td
                        className='has-text-centered middle has-text-weight-semibold'
                        colSpan={3}
                      >
                        Jumlah Nilai (
                        {totalscr?.sc
                          ? Math.round(totalscr?.sc * 100) / 100
                          : 0}{' '}
                        / {totalscr?.dv} x 100)
                      </td>
                      <td
                        colSpan={3}
                        className={`middle${isEdit ? '' : ' has-text-right'}`}
                      >
                        {!isEdit && totalscr?.total}
                        {isEdit && (
                          <TextInput
                            readOnly={true}
                            name='totalScore'
                            style={{ textAlign: 'right', minWidth: 90 }}
                            defaultValue={totalscr?.total ?? 0}
                            placeholder='Jumlah Nilai'
                            label='noLabel'
                          />
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MViewSummaryAnswerForm;

const TextInput = ({
  name,
  type,
  onChange,
  onKeyDown,
  disabled,
  readOnly,
  defaultValue,
  placeholder,
  label,
  style,
}) => (
  <div className='field'>
    {label && label !== 'noLabel' && (
      <label className='label' htmlFor={name}>
        {label}
      </label>
    )}
    <div className='control'>
      <input
        className='input is-size-6'
        id={name}
        readOnly={readOnly}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={defaultValue}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        style={{ ...style }}
      />
    </div>
  </div>
);
