import { SETTING_GET, SETTING_EDIT } from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {};

const settingGet = (state, payload) => {
  let setting = payload.setting && payload.setting.title ? payload.setting : {};
  return setting;
};

const settingEdit = (state, payload) => {
  let setting = payload.setting;
  let description = state.description && JSON.parse(state.description);
  description = {...description, ...setting};
  console.log(description);
  let newDescription = JSON.stringify(description);
  return {
    ...state,
    description: newDescription,
  };
};

export default createReducer(initialState, {
  [SETTING_GET]: settingGet,
  [SETTING_EDIT]: settingEdit,
});
