import { authLogout } from '../../login/redux/authAction';
import { toastr } from 'react-redux-toastr';

export const checkRes = (res, fetchData, dispatch, status, history) => {
  if (!res) {
    const err = new Error('Terjadi kesalahan...');
    return err;
  }
  if (res.status === 'unauthorized') {
    dispatch(authLogout());
    history.push('/');
    const err = new Error('Anda tidak memiliki otoritas!');
    return err;
  }
  if (res.message === 'jwt expired' || res.message === 'jwt malformed') {
    let unamePass = localStorage.getItem('unamePass');
    if (unamePass && unamePass.length > 0) {
      return window.ReactNativeWebView.postMessage('logout');
    } else {
      return window.open(window.location.href, '_self');
    }
  }
  if (status === 200 && fetchData.status !== 200) {
    const err = new Error(res.message);
    return err;
  }
  if (status === 201 && fetchData.status !== 201) {
    const err = new Error(res.message);
    return err;
  }
  return false;
};

export const checkErr = (err) => {
  console.log(err);
  toastr.error('Error', err.message);
};
