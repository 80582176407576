import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shareAnswerKey } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';

const actions = {
  closeModal,
  shareAnswerKey,
};

class ModalShareAnswerKey extends Component {
  onShare = ({ auth, history, item }) => {
    const { shareAnswerKey, closeModal } = this.props;
    shareAnswerKey(auth, history, item.id);
    closeModal();
  };

  render() {
    const { data, closeModal } = this.props;
    const item = data.item;
    return (
      <ModalBig closeModal={closeModal}>
        <ModalHeader
          title='Konfirmasi Share'
          color='info'
          icon='shield-half-full'
          closeModal={closeModal}
        />
        <section className='modal-card-body is-size-6'>
          <p className='my-3 mx-1'>
            Apakah Anda yakin ingin membagikan kunci jawaban{' '}
            <span className='has-text-info has-text-weight-semibold'>{`${item.title}`}</span>{' '}
            ?
          </p>
        </section>
        <ModalFooter
          closeModal={closeModal}
          actionBtn={
            <button
              onClick={() => this.onShare(data)}
              className='button is-info is-small is-rounded is-outlined'
            >
              <i className='is-size-6 mdi mdi-share icon' />
            </button>
          }
        />
      </ModalBig>
    );
  }
}

function ModalHeader({ title, color, icon, closeModal }) {
  return (
    <header className={`modal-card-head has-background-${color}`}>
      <div className='modal-card-title has-text-white has-text-weight-normal'>
        <i className={`is-size-4 mdi mdi-${icon ? icon : 'shield'} icon`} />{' '}
        {title}
      </div>
      <button
        onClick={closeModal}
        className='delete'
        aria-label='close'
      ></button>
    </header>
  );
}

function ModalFooter({ closeModal, actionBtn }) {
  return (
    <footer className='modal-card-foot'>
      {actionBtn && actionBtn}
      <button
        className='button custom-grey is-small is-rounded is-outlined'
        onClick={closeModal}
      >
        <i className='is-size-6 mdi mdi-arrow-left icon' />
      </button>
    </footer>
  );
}

function ModalBig({ children, closeModal }) {
  return (
    <div className='modal is-active'>
      <div className='modal-background' onClick={closeModal}></div>
      <div className='modal-card'>{children}</div>
    </div>
  );
}

export default connect(null, actions)(ModalShareAnswerKey);
