import {
  mdiArrowLeft,
  mdiContentSave,
  mdiDelete,
  mdiEye,
  mdiPencil,
  mdiPlus,
  mdiReload,
} from '@mdi/js';
import Icon from '@mdi/react';

function Button({
  variant,
  cvariant,
  icon,
  onClick,
  label,
  disabled,
  loading,
  type,
}) {
  const detailVariants = {
    add: {
      icon: mdiPlus,
      label: 'Tambah',
      color: ' is-info is-outlined',
      text: 'info',
    },
    view: {
      icon: mdiEye,
      label: 'Lihat',
      color: ' is-info is-outlined',
      text: 'info',
    },
    reload: {
      icon: mdiReload,
      label: 'Reload',
      color: ' is-info is-outlined',
      text: 'info',
    },
    info: {
      icon: mdiEye,
      label: 'Lihat',
      color: ' is-info is-outlined',
      text: 'info',
    },
    edit: {
      icon: mdiPencil,
      label: 'Edit',
      color: ' is-info is-outlined',
      text: 'info',
    },
    save: {
      icon: mdiContentSave,
      label: 'Simpan',
      color: ' is-primary is-outlined',
      text: 'primary',
    },
    back: {
      icon: mdiArrowLeft,
      label: 'Kembali',
      color: '',
      text: 'dark',
    },
    delete: {
      icon: mdiDelete,
      label: 'Hapus',
      color: ' is-danger is-outlined',
      text: 'danger',
    },
    csave: {
      label: 'Simpan',
      color: ' is-primary',
      text: 'primary',
    },
    cback: {
      label: 'Kembali',
      color: ' is-light',
      text: 'dark',
    },
    cdelete: {
      label: 'Hapus',
      color: ' is-danger',
      text: 'danger',
    },
  };

  if (cvariant)
    return (
      <button
        className={`button is-small is-rounded${
          cvariant && detailVariants[cvariant].color
        }`}
        onClick={onClick}
      >
        {label ? label : detailVariants[cvariant].label}
      </button>
    );

  return (
    <button
      className={`button is-small is-rounded${
        variant && detailVariants[variant].color
      }${loading ? ' is-loading' : ''}`}
      onClick={onClick}
      disabled={disabled ? true : false}
      type={type ? type : 'button'}
    >
      <span className='icon is-small'>
        <Icon path={icon ? icon : detailVariants[variant].icon} size={1} />
      </span>
    </button>
  );
}

export default Button;
