import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
import {
  kelassGet,
  kelasJenjangsGet,
  kelasTingkatsGet,
  kelasAdd,
  kelasEdit,
  kelasGet,
  kelassSync,
  kelasDelete,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../app/common/helpers/fetchHelpers';
import { logsFunc } from '../../../../app/common/helpers/othersHelpers';

export const getKelass = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getKelass' });
    const formData = new FormData();
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'kelas/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
      };
      dispatch(kelassGet({ total, items, search }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getKelasJenjangs = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getKelasJenjangs' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'jenjang/all',
        formData,
        dispatch
      );
      const jenjangs = res.jenjangs;
      dispatch(kelasJenjangsGet(jenjangs));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getKelasTingkats = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getKelasTingkats' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'tingkat/all',
        formData,
        dispatch
      );
      const tingkats = res.tingkats;
      dispatch(kelasTingkatsGet(tingkats));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addKelas = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addKelas' });
    let logs = logsFunc('create', auth.userid, auth.username, null);
    const formData = new FormData();
    formData.append('code', values.code);
    formData.append('title', values.title);
    formData.append('jenjangId', values.jenjangId);
    formData.append('tingkatId', values.tingkatId);
    formData.append('description', values.description);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'kelas/add/',
        formData,
        dispatch
      );
      const kelas = res.kelas;
      dispatch(kelasAdd(kelas));
      history.push('/akademik/kelas/' + kelas.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getKelas = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getKelas' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'kelas/detail/' + id,
        formData,
        dispatch
      );
      const kelas = res.kelas;
      dispatch(kelasGet(kelas));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editKelas = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editKelas' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('code', values.code);
    formData.append('title', values.title);
    formData.append('jenjangId', values.jenjangId);
    formData.append('tingkatId', values.tingkatId);
    formData.append('description', values.description);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'kelas/edit/' + values.id,
        formData,
        dispatch
      );
      const kelas = res.kelas;
      dispatch(kelasEdit(kelas));
      history.push('/akademik/kelas/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const statusKelas = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'statusKelas' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('status', values.status);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'kelas/status/' + values.id,
        formData,
        dispatch
      );
      const kelas = res.kelas;
      dispatch(kelasEdit(kelas));
      history.push('/akademik/kelas/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const syncKelas = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'syncKelas' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'kelas/sync/',
        formData,
        dispatch
      );
      if (res.kelass) {
        const kelass = res.kelass;
        const syncKelass = res.syncKelass;
        dispatch(kelassSync({ kelass, syncKelass }));
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const syncSaveKelas = (
  auth,
  history,
  kelassFilter,
  jenjangs,
  tingkats
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'syncSaveKelas' });
    let logs = logsFunc('create', auth.userid, auth.username, null);
    const formData = new FormData();
    let newKelasFilter = [];
    for (const obj of kelassFilter) {
      let tingkat = tingkats.filter((t) => t.code === obj.tingkat)[0];
      let jenjang = jenjangs.filter((t) => t.code === obj.jenjang)[0];
      if (obj?.selected) {
        newKelasFilter.push({
          ...obj,
          tingkatId: tingkat.id,
          jenjangId: jenjang.id,
        });
      }
    }
    formData.append('syncData', JSON.stringify(newKelasFilter));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'kelas/sync-save/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      dispatch(
        kelassGet({
          total,
          items,
          search: {
            currentPage: '',
            searchText: '',
            itemPerPage: '',
          },
        })
      );
      history.push('/akademik/kelas');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteKelas = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteKelas' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'kelas/delete/' + id,
        formData,
        dispatch
      );
      const kelas = res.kelas;
      dispatch(kelasDelete(kelas));
      history.push('/akademik/kelas');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
