export const TUGASS_GET = 'TUGASS_GET';
export const TUGAS_ADD = 'TUGAS_ADD';
export const TUGAS_GET = 'TUGAS_GET';
export const STUDENT_ANSWER_GET = 'STUDENT_ANSWER_GET';
export const TUGAS_EDIT = 'TUGAS_EDIT';
export const TUGAS_DETAILSCORES = 'TUGAS_DETAILSCORES';
export const TUGAS_LISTSCORES = 'TUGAS_LISTSCORES';
export const TUGAS_UPLOADFILE = 'TUGAS_UPLOADFILE';
export const SETTING_GET = 'SETTING_GET';
export const TUGAS_DELETESTUDENTANSWER = 'TUGAS_DELETESTUDENTANSWER';
export const TUGAS_DELETE = 'TUGAS_DELETE';
export const TUGAS_RESET_SEARCH = 'TUGAS_RESET_SEARCH';
