import {
  TESS_GET,
  TES_ADD,
  TES_GET,
  STUDENT_ANSWER_GET,
  TES_EDIT,
  TES_DETAILSCORES,
  TES_LISTSCORES,
  SETTING_GET,
  TES_DELETESTUDENTANSWER,
  TES_DELETE,
  TES_RESET_SEARCH,
} from './reduxConstant';

export const tessGet = (tess) => {
  return {
    type: TESS_GET,
    payload: {
      tess,
    },
  };
};

export const tesAdd = (tes) => {
  return {
    type: TES_ADD,
    payload: {
      tes,
    },
  };
};

export const tesGet = (tes) => {
  return {
    type: TES_GET,
    payload: {
      tes,
    },
  };
};

export const studentAnswerGet = (answers) => {
  return {
    type: STUDENT_ANSWER_GET,
    payload: {
      answers,
    },
  };
};

export const tesEdit = (tes) => {
  return {
    type: TES_EDIT,
    payload: {
      tes,
    },
  };
};

export const tesDetailScores = (detailScores) => {
  return {
    type: TES_DETAILSCORES,
    payload: {
      detailScores,
    },
  };
};

export const tesListScores = (listScores) => {
  return {
    type: TES_LISTSCORES,
    payload: {
      listScores,
    },
  };
};

export const settingGet = (setting) => {
  return {
    type: SETTING_GET,
    payload: {
      setting,
    },
  };
};

export const tesDeleteStudentAnswer = () => {
  return {
    type: TES_DELETESTUDENTANSWER,
    payload: {},
  };
};

export const tesDelete = (tes) => {
  return {
    type: TES_DELETE,
    payload: {
      tes,
    },
  };
};

export const tesResetSearch = () => {
  return {
    type: TES_RESET_SEARCH,
    payload: {},
  };
};
