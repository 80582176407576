import React, { Component, Fragment } from 'react';
import { SITE_ADDRESS } from '../../common/data/siteConfig';
import Menu from './menu/Menu';
import UserProfile from '../../../pictures/user-default.png';
import Logout from '../../common/icons/Logout';

class SideMenu extends Component {
  state = {
    openDropdown: { title: '', open: false },
  };

  handleToggleSubmenu = (title) => {
    this.setState({
      openDropdown: {
        title,
        open:
          this.state.openDropdown.title === title
            ? !this.state.openDropdown.open
            : true,
      },
    });
  };

  componentDidMount() {
    this.handleTogglerLoad();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.swipe !== this.props.swipe) {
      let sideMenu = document.getElementsByClassName('menu-container')[0];
      sideMenu.classList.add('active');
      this.props.handleTogglerOnOff(this.props.isTogglerActive);
      this.props.swipeToggleStatus(this.props.swipe);
      const burger = document.getElementById('burger');
      if (burger) {
        burger.classList.add('active');
        burger.classList.remove('not-active');
      }
    }
  }

  handleTogglerLoad = () => {
    let container = document.body.parentElement;
    let navBurger = document.getElementsByClassName('navbar-burger')[0];
    let sideMenu = document.getElementsByClassName('menu-container')[0];
    let logoutBtn = document.getElementById('logoutBtn');
    let menuList = document.getElementsByClassName('menu-list')[1];
    container.addEventListener('click', (event) => {
      if (navBurger.contains(event.target)) {
        this.props.handleInsideClick(true);
      } else {
        this.props.handleInsideClick(false);
      }
      if (
        this.props.toggler &&
        sideMenu.classList.contains('active') &&
        menuList.contains(event.target)
      ) {
        this.props.handleTogglerOnOff(false);
        this.props.swipeToggleStatus(false);
        sideMenu.classList.remove('active');
        const burger = document.getElementById('burger');
        if (burger) {
          burger.classList.add('not-active');
          burger.classList.remove('active');
        }
      }
      if (!this.props.inside && sideMenu.classList.contains('active')) {
        this.props.handleTogglerOnOff(false);
        this.props.swipeToggleStatus(false);
        sideMenu.classList.remove('active');
        const burger = document.getElementById('burger');
        if (burger) {
          burger.classList.add('not-active');
          burger.classList.remove('active');
        }
      }
      if (this.props.swipe && sideMenu.classList.contains('active')) {
        this.props.handleTogglerOnOff(false);
        this.props.swipeToggleStatus(false);
        sideMenu.classList.remove('active');
        const burger = document.getElementById('burger');
        if (burger) {
          burger.classList.add('not-active');
          burger.classList.remove('active');
        }
      }
      if (event.target.classList.contains('has-submenu')) {
        this.props.handleTogglerOnOff(true);
        this.props.handleInsideClick(true);
        sideMenu.classList.remove('not-active');
        sideMenu.classList.add('active');
        const burger = document.getElementById('burger');
        if (burger) {
          burger.classList.remove('not-active');
          burger.classList.add('active');
        }
      }
      if (logoutBtn.contains(event.target)) {
        this.props.handleTogglerOnOff(false);
        this.props.swipeToggleStatus(false);
      }
    });
  };

  render() {
    const { auth, menus, logout, toggler, pathname, profile } = this.props;
    return (
      <Fragment>
        <div
          id='sm'
          className={`menu-container has-background-white${
            toggler === true ? ' active' : ''
          }`}
        >
          <div
            className='menu-wrapper py-1'
            style={{ backgroundColor: '#263544' }}
          >
            <aside className='menu'>
              <div className='menu-list profile-sidebar mt-2'>
                <div className='level mb-3'>
                  <div className='level-item'>
                    <figure className='image is-48x48'>
                      <div
                        style={{
                          width: 48,
                          height: 48,
                          backgroundImage: `url(${
                            profile && profile.photo
                              ? SITE_ADDRESS + profile.photo
                              : UserProfile
                          })`,
                          borderRadius: '50%',
                          backgroundSize: 'cover',
                        }}
                      />
                    </figure>
                  </div>
                </div>
                <div className='mb-2' style={{ wordWrap: 'break-word' }}>
                  <div className='flex-column-center'>
                    <>
                      <div className='subtitle is-size-6 mt-1 has-text-weight-semibold has-text-grey-lighter'>
                        {auth.username && auth.username.length < 6 ? (
                          <span className='is-uppercase'>
                            {auth.username.toLowerCase()}
                          </span>
                        ) : (
                          <span className='is-capitalized'>
                            {auth.username}
                          </span>
                        )}
                      </div>
                    </>
                  </div>
                </div>
              </div>
              <ul
                className='menu-list'
                style={{ borderTop: '1px solid #1F2530' }}
              >
                <li>
                  <div
                    id='Navigation'
                    className='disable-select is-size-6 has-text-grey-lighter'
                  >
                    Menu
                  </div>
                </li>
                {menus.map((m) => (
                  <Menu
                    pathname={pathname}
                    key={m.id}
                    dataMenu={m}
                    dataAuth={auth.authorities}
                    openMenu={this.state.openDropdown}
                    handleToggleSubmenu={this.handleToggleSubmenu}
                  />
                ))}
                <li>
                  <div
                    id='logoutBtn'
                    onClick={() => logout('logoutNav')}
                    className='disable-select is-size-6 custom-text-overflow custom-hover-light-info flex justify-start align-center'
                  >
                    <div
                      className='flex align-center'
                      style={{ paddingTop: 6, paddingBottom: 6 }}
                    >
                      <Logout size={15} color={'#ffffff'} />
                      <span className='ml-2'>Logout</span>
                    </div>
                  </div>
                </li>
              </ul>
              <br />
              <br />
            </aside>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SideMenu;
