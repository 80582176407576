import {
  MONITORINGS_GET,
  MONITORING_GET,
  MONITORINGSTUDENTS_GET,
  MONITORINGSTUDENT_GET,
  MONITORING_FILTER,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';
import { parseISO, addMinutes } from 'date-fns';

const initialState = {
  search: {
    year: '',
    sem: '',
    jenjang: '',
  },
  totalTess: 0,
  tess: [],
  students: {},
  filter: {},
};

const monitoringsGet = (state, payload) => {
  const monitorings = payload.monitorings;

  let newTugass = [];
  for (const tugas of monitorings.tugass) {
    let newTgs = {
      ...tugas,
      start: parseISO(tugas.publishDate),
      end: addMinutes(parseISO(tugas.publishDate), 60),
      allDayEvent: false,
      type: 'tugas',
      backgroundColor: '#00947e'
    };
    newTugass = [...newTugass, newTgs];
  }

  let newTess = [];
  for (const tes of monitorings.tess) {
    let setting = tes.setting && JSON.parse(tes.setting);
    let settingTime = setting?.time ? setting.time : 60;
    let newTes = {
      ...tes,
      start: parseISO(tes.publishDate),
      end: addMinutes(parseISO(tes.publishDate), settingTime),
      allDayEvent: false,
      type: 'tes',
      backgroundColor: '#3e8ed0'
    };
    newTess = [...newTess, newTes];
  }
  
  return {
    ...state,
    totalTugass: monitorings.totalTugass,
    tugass: [...newTugass],
    totalTess: monitorings.totalTess,
    tess: [...newTess],
  };
};

const monitoringGet = (state, payload) => {
  let tesId = payload.monitoring?.id;
  if (!tesId) return { ...state };
  let newState = [];
  if (tesId) {
    let tess = [
      ...state.tess.filter((ts) => ts.id !== tesId),
      payload.monitoring,
    ];
    newState = { ...state, tess };
  }
  return newState;
};

const monitoringStudentsGet = (state, payload) => {
  let monitor = payload.monitorStudents;
  let students = monitor && monitor.students;
  let id = monitor && monitor.id;
  let stateStudents = { ...state.students, [id]: students };
  return {
    ...state,
    students: stateStudents,
  };
};

const monitoringStudentGet = (state, payload) => {
  let tesId = payload.monitoring?.tes?.id;
  if (!tesId) return { ...state };
  let newState = [];
  if (tesId) {
    let tess = [
      ...state.tess.filter((ts) => ts.id !== tesId),
      payload.monitoring.tes,
    ];
    newState = { ...state, tess };
  }

  return newState;
};

const monitoringFilter = (state, payload) => {
  let filter = payload.monitoring?.filter;
  let id = payload.monitoring?.id;
  if (!filter) return { ...state };
  let stateFilter = [];
  if (filter) {
    stateFilter = {
      ...state.filter,
      [id]: filter,
    };
  }
  return { ...state, filter: stateFilter };
};

export default createReducer(initialState, {
  [MONITORINGS_GET]: monitoringsGet,
  [MONITORING_GET]: monitoringGet,
  [MONITORINGSTUDENTS_GET]: monitoringStudentsGet,
  [MONITORINGSTUDENT_GET]: monitoringStudentGet,
  [MONITORING_FILTER]: monitoringFilter,
});
