import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { sessionLogout as logout } from '../login/redux/authApi';
import Lock from '../common/icons/Lock';

const actions = {
  logout,
};

class ModalNotAuthorized extends Component {
  render() {
    const { history, logout } = this.props;

    return (
      <div className='modal is-active'>
        <div
          className='modal-background'
          style={{ backgroundColor: 'black', opacity: 1 }}
        ></div>
        <div className='modal-card'>
          <header className='modal-card-head'>
            <p className='modal-card-title has-text-danger flex justify-start align-center'>
              <Lock size={22} color={'#f14668'} />
              <span className='ml-1'>Tidak Memiliki otoritas</span>
            </p>
          </header>
          <section className='modal-card-body'>
            <div>
              <br />
              <p className='has-text-centered'>Anda tidak memiliki otoritas!</p>
              <p className='has-text-centered' style={{ marginTop: 15 }}>
                {history.location.pathname !== '/dashboard' && (
                  <button
                    className='button is-small is-rounded is-primary is-outlined'
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    <i className='fas fa-arrow-left icon mr-1'></i> Kembali
                  </button>
                )}
                <button
                  className='button is-small is-rounded is-danger is-outlined ml-2'
                  onClick={() => logout('logoutModal', history)}
                >
                  <i className='fas fa-sign-out-alt icon mr-1'></i>
                  Logout
                </button>
              </p>
              <br />
            </div>
          </section>
          <footer className='modal-card-foot'></footer>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(null, actions)(ModalNotAuthorized));
