import React, { Component } from 'react';
import { connect } from 'react-redux';
import { statusYear } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';

const actions = {
  closeModal,
  statusYear,
};

class ModalStatus extends Component {
  setStatus = () => {
    const { data, closeModal, statusYear } = this.props;
    const newValues = { ...data.item };
    newValues.status = data.item.status ? false : true;
    statusYear(data.auth, data.history, newValues);
    closeModal();
  };

  render() {
    const { data, closeModal } = this.props;
    const item = data.item;
    return (
      <div className='modal is-active'>
        <div className='modal-background' onClick={closeModal}></div>
        <div className='modal-card'>
          <header className='modal-card-head'>
            <div
              className={`modal-card-title has-text-${
                item.status ? 'danger' : 'info'
              } has-text-weight-normal`}
            >
              <i
                className={`is-size-6 mdi mdi-${
                  item.status ? 'close-circle' : 'check-circle'
                } mr-2`}
              />
              Set {item.status ? 'Non Aktif' : 'Aktif'}
            </div>
            <button
              onClick={closeModal}
              className='delete'
              aria-label='close'
            ></button>
          </header>
          <section className='modal-card-body is-size-6'>
            <p className='my-3 mx-1'>
              Apakah Anda ingin set tahun ajaran{' '}
              <span className='has-text-danger has-text-weight-semibold is-capitalized'>
                {item.title}
              </span>{' '}
              menjadi {item.status ? 'non aktif' : 'aktif'} ?
            </p>
          </section>
          <footer className='modal-card-foot'>
            <button
              onClick={() => this.setStatus()}
              className='button is-info is-small is-rounded is-outlined'
            >
              <i className='is-size-6 mdi mdi-content-save icon' />
            </button>
            <button
              className='button custom-grey is-small is-rounded is-outlined'
              onClick={closeModal}
            >
              <i className='is-size-6 mdi mdi-arrow-left icon' />
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

export default connect(null, actions)(ModalStatus);
