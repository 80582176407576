import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../app/common/reduxs/async/asyncActions';
import {
  tessGet,
  tesAdd,
  tesGet,
  studentAnswerGet,
  tesEdit,
  tesDetailScores,
  tesListScores,
  tesDeleteStudentAnswer,
  tesDelete,
  tesResetSearch,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../app/common/reduxs/async/asyncConstant';
import { checkErr } from '../../../../app/common/helpers/checkRes';
import { fetchFunc } from '../../../../app/common/helpers/fetchHelpers';
import { logsFunc } from '../../../../app/common/helpers/othersHelpers';
import { SITE_ADDRESS } from '../../../../app/common/data/siteConfig';
import { nanoid } from 'nanoid';
import { saveAs } from 'file-saver';

export const getTess = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText,
  searchMoreText,
  isReset
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTess' });
    const formData = new FormData();
    if (isReset === 'reset') {
      dispatch(tesResetSearch());
    }
    const allDataAccess = auth.authorities.subm.materi_all;
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    formData.append('allData', allDataAccess);
    formData.append('searchMoreText', JSON.stringify(searchMoreText));
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'tes/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
        ...searchMoreText,
      };
      dispatch(tessGet({ total, items, search }));
      if (isReset === 'reset') {
        history.push('/pembelajaran/tes');
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const addTes = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'addTes' });
    let logs = logsFunc('create', auth.userid, auth.username, null);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('questions', JSON.stringify(values.questions));
    formData.append('answers', JSON.stringify(values.answers));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tes/add/' + values.id,
        formData,
        dispatch
      );
      const tes = res.tes;
      dispatch(tesAdd(tes));
      history.push('/pembelajaran/tes/edit/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const copyTes = (auth, history, values, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'copyTes' });
    let logs = logsFunc('create', auth.userid, auth.username, null);
    const formData = new FormData();
    formData.append('oldId', values.id);
    formData.append('newId', id);
    formData.append('title', 'copy of ' + values.title);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tes/copy/' + values.id,
        formData,
        dispatch
      );
      const tes = res.tes;
      dispatch(tesAdd(tes));
      history.push('/pembelajaran/tes/edit/' + id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
// get all data tes to be select & copy
export const getTessCopy = (
  auth,
  history,
  itemPerPage,
  currentPage,
  searchText,
  searchMoreText,
  isReset
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTessCopy' });
    const formData = new FormData();
    if (isReset === 'reset') {
      dispatch(tesResetSearch());
    }
    const allDataAccess = auth.authorities.subm.materi_all;
    formData.append('itemPerPage', itemPerPage ? itemPerPage : 10);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    formData.append('allData', allDataAccess);
    formData.append('searchMoreText', JSON.stringify(searchMoreText));
    try {
      const res = await fetchFunc(
        'gets',
        auth,
        history,
        'tes/copy-all/',
        formData,
        dispatch
      );
      const items = res.items;
      const total = res.total;
      const search = {
        currentPage,
        searchText,
        itemPerPage: itemPerPage ? itemPerPage : 10,
        ...searchMoreText,
      };
      dispatch(tessGet({ total, items, search }));
      if (isReset === 'resetcopy') {
        history.push('/pembelajaran/tes/copy');
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getTes = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTes' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'tes/detail/' + id,
        formData,
        dispatch
      );
      const tes = res.tes;
      dispatch(tesGet(tes));
      dispatch(asyncActionFinish());
      return tes;
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const editTes = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'editTes' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('questions', JSON.stringify(values.questions));
    formData.append('answers', JSON.stringify(values.answers));
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tes/edit/' + values.id,
        formData,
        dispatch
      );
      const tes = res.tes;
      dispatch(tesEdit(tes));
      history.push('/pembelajaran/tes/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const publishTes = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'publishTes' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('published', values.published);
    formData.append('year', values.year);
    formData.append('sem', values.sem);
    formData.append('jenjang', values.jenjang);
    formData.append('tingkat', values.tingkat);
    formData.append('kelas', values.kelas && values.kelas.toString());
    formData.append('code', values.code);
    formData.append('pelajaran', values.pelajaran);
    formData.append('tags', JSON.stringify(values.tags));
    formData.append('publishDate', values.publishDate);
    formData.append('time', values.time);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tes/publish/' + values.id,
        formData,
        dispatch
      );
      const tes = res.tes;
      dispatch(tesEdit(tes));
      history.push('/pembelajaran/tes/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const submitTesScores = (auth, history, id, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'submitTesScores' });
    const formData = new FormData();
    formData.append('scores', JSON.stringify(values));
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tes/submit-scores/' + id,
        formData,
        dispatch
      );
      const tes = res.tes;
      dispatch(tesEdit(tes));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const publishTesScores = (auth, history, id, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'publishTesScores' });
    const formData = new FormData();
    formData.append('scores', JSON.stringify(values));
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tes/publish-scores/' + id,
        formData,
        dispatch
      );
      const tes = res.tes;
      dispatch(tesEdit(tes));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getDetailTesScores = (auth, history, id, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getDetailTesScores' });
    const formData = new FormData();
    formData.append('year', values.year);
    formData.append('sem', values.sem);
    formData.append('kelas', values.kelas);
    formData.append('pelajaran', values.pelajaran);
    formData.append('code', values.kodePelajaran);
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'tes/detail-scores/' + id,
        formData,
        dispatch
      );
      const detailScores = res.detailScores;
      dispatch(tesDetailScores(detailScores));
      dispatch(asyncActionFinish());
      return detailScores;
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getListTesScores = (auth, history, id, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getListTesScores' });
    const formData = new FormData();
    formData.append('idx', values.idx);
    formData.append('typeScoring', values.typeScoring);
    formData.append('detailScoring', values.detailScoring);
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'tes/list-scores/' + id,
        formData,
        dispatch
      );
      const listScores = res.listScores;
      dispatch(tesListScores(listScores));
      dispatch(asyncActionFinish());
      return listScores;
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getStudentDetail = (auth, history, nis) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getStudentDetail' });
    console.log(nis);
    const formData = new FormData();
    formData.append('nis', nis);
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'tes/student-detail/' + nis,
        formData,
        dispatch
      );
      const studentDetail = res.studentDetail;
      dispatch(asyncActionFinish());
      return studentDetail;
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const sendListTesScores = (auth, history, id, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'sendListTesScores' });
    const formData = new FormData();
    formData.append('idx', values.idx);
    formData.append('typeScoring', values.typeScoring);
    formData.append('detailScoring', values.detailScoring);
    formData.append('rowScores', values.rowScores);
    formData.append('kelas', values.kelas);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tes/send-list-scores/' + id,
        formData,
        dispatch
      );
      const tes = res.tes;
      dispatch(tesEdit(tes));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteSyncScores = (auth, history, id, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteSyncScores' });
    const formData = new FormData();
    formData.append('kelas', values.kelas);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tes/delete-sync-scores/' + id,
        formData,
        dispatch
      );
      const tes = res.tes;
      dispatch(tesEdit(tes));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getStudentAnswer = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getStudentAnswer' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'get',
        auth,
        history,
        'tes/student-answer/' + id,
        formData,
        dispatch
      );
      const studentAnswer = res.studentAnswer;
      dispatch(studentAnswerGet(studentAnswer));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const saveAnswerKey = (auth, history, id, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'saveAnswerKey' });
    const formData = new FormData();
    formData.append('answers', JSON.stringify(values));
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tes/save-answer-key/' + id,
        formData,
        dispatch
      );
      const tes = res.tes;
      dispatch(tesEdit(tes));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const shareAnswerKey = (auth, history, id, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'shareAnswerKey' });
    const formData = new FormData();
    formData.append('values', JSON.stringify(values));
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tes/share-answer-key/' + id,
        formData,
        dispatch
      );
      const tes = res.tes;
      dispatch(tesEdit(tes));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteTesStudentAnswer = (auth, history, tesId, studentTesId) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteTesStudentAnswer' });
    const formData = new FormData();
    formData.append('tesId', tesId);
    formData.append('studentTesId', studentTesId);
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'tes/delete-student-answer/' + studentTesId,
        formData,
        dispatch
      );
      const tes = res.tes;
      dispatch(tesDeleteStudentAnswer(tes));
      history.push('/pembelajaran/tes/penilaian/' + tesId);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteTesAllStudentAnswer = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({
      type: ASYNC_ACTION_START,
      payload: 'deleteTesAllStudentAnswer',
    });
    const formData = new FormData();
    try {
      await fetchFunc(
        'delete',
        auth,
        history,
        'tes/delete-all-student-answer/' + id,
        formData,
        dispatch
      );
      dispatch(tesDeleteStudentAnswer());
      history.push('/pembelajaran/tes/' + id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteShareAnswerKey = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteShareAnswerKey' });
    const formData = new FormData();
    formData.append('id', id);
    try {
      const res = await fetchFunc(
        'edit',
        auth,
        history,
        'tes/delete-share-answer-key/' + id,
        formData,
        dispatch
      );
      const tes = res.tes;
      dispatch(tesEdit(tes));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const uploadImage = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'uploadImage' });
    let logAction = values.logs ? 'edit' : 'create';
    let logs = logsFunc(logAction, auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('keyItem', values.keyItem);
    formData.append('questions', JSON.stringify(values.questions));
    formData.append('answers', JSON.stringify(values.answers));
    formData.append('file', values.file);
    formData.append('filename', values.filename);
    formData.append('filesize', values.filesize);
    formData.append('filetype', values.filetype);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'uploadAttach',
        auth,
        history,
        'tes/upload-image/' + values.id,
        formData,
        dispatch
      );
      const tes = res?.tes;
      if (tes) {
        dispatch(tesEdit(tes));
        history.push('/pembelajaran/tes/edit/' + values.id);
      }
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const viewImage = (auth, history, values, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'viewImage' });
    const formData = new FormData();
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    formData.append('link', file.link);
    try {
      const fetchData = await fetch(
        SITE_ADDRESS + 'tes/download-file/' + values.id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const blob = await fetchData.blob();
      saveAs(blob, file.link.split('/')[file.link.split('/').length - 1]);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteImage = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteImage' });
    let logs = logsFunc('edit', auth.userid, auth.username, values.logs);
    const formData = new FormData();
    formData.append('questionKey', values.key);
    formData.append('link', values.picture.filelink);
    formData.append('logs', logs);
    try {
      const res = await fetchFunc(
        'deleteFile',
        auth,
        history,
        'tes/delete-image/' + values.id,
        formData,
        dispatch
      );
      const tes = res.tes;
      dispatch(tesEdit(tes));
      history.push('/pembelajaran/tes/edit/' + values.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const importTes = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'importTes' });
    try {
      let questions = [];
      let answers = [];
      for (let i = 0; i < values.numberOfQuestion; i++) {
        let item = values.data[i];
        let key = nanoid();
        let tipe = item.Tipe.toLowerCase();
        let questionType;
        if (tipe === 'pilihan') {
          questionType = 'optionQuestion';
        } else if (tipe === 'b/s') {
          questionType = 'rightWrongQuestion';
        } else if (tipe === 'singkat') {
          questionType = 'shortQuestion';
        } else {
          questionType = 'longQuestion';
        }
        let newQuestion = {
          no: i + 1,
          key: key,
          questionType: questionType,
          question: item.Pertanyaan,
        };
        newQuestion.options =
          tipe === 'pilihan' || tipe === 'b/s'
            ? item.Pilihan.split('\r\n')?.filter((el) => {
                return el;
              })
            : [];
            
        questions = [...questions, newQuestion];
        let newAnswer = {
          no: i + 1,
          key: key,
          answer: item.Jawaban,
          explanation: item.Penjelasan,
        };
        answers = [...answers, newAnswer];
      }
      let logs = logsFunc('create', auth.userid, auth.username, null);
      const formData = new FormData();
      formData.append('id', nanoid(32));
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('questions', JSON.stringify(questions));
      formData.append('answers', JSON.stringify(answers));
      formData.append('logs', logs);
      const res = await fetchFunc(
        'add',
        auth,
        history,
        'tes/import/',
        formData,
        dispatch
      );
      const importResult = res.importResult;
      history.push('/pembelajaran/tes/edit/' + importResult.id);
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteTes = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteTes' });
    const formData = new FormData();
    try {
      const res = await fetchFunc(
        'delete',
        auth,
        history,
        'tes/delete/' + id,
        formData,
        dispatch
      );
      const tes = res.tes;
      dispatch(tesDelete(tes));
      history.push('/pembelajaran/tes');
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
