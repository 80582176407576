import {
  TESS_GET,
  TES_ADD,
  TES_GET,
  STUDENT_ANSWER_GET,
  TES_EDIT,
  TES_DETAILSCORES,
  TES_LISTSCORES,
  SETTING_GET,
  TES_DELETE,
  TES_RESET_SEARCH,
  TES_DELETESTUDENTANSWER,
} from './reduxConstant';
import { createReducer } from '../../../../app/common/utilities/reducerUtils';

const initialState = {
  total: 0,
  search: {
    year: '',
    sem: '',
    jenjang: '',
    tingkat: '',
    kelas: '',
    pelajaran: '',
    published: 'all',
  },
  items: [],
  studentAnswer: [],
  detailScores: {},
  listScores: [],
  setting: {},
};

const tessGet = (state, payload) => {
  const tess = payload.tess;
  return {
    ...state,
    total: tess.total,
    items: [...tess.items],
    search: { ...tess.search },
  };
};

const tesAdd = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) + 1,
    items: [...state.items, payload.tes],
  };
};

const tesGet = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.tes.id + ''),
      payload.tes,
    ],
  };
};

const studentAnswerGet = (state, payload) => {
  return {
    ...state,
    studentAnswer: [...payload.answers],
  };
};

const tesEdit = (state, payload) => {
  return {
    ...state,
    items: [
      ...state.items.filter((i) => i.id + '' !== payload.tes.id + ''),
      payload.tes,
    ],
  };
};
// Sync score to academic
const tesDetailScores = (state, payload) => {
  return {
    ...state,
    detailScores: { ...payload.detailScores },
  };
};
// Sync score to academic
const tesListScores = (state, payload) => {
  return {
    ...state,
    listScores: [...payload.listScores],
  };
};

const settingGet = (state, payload) => {
  return {
    ...state,
    setting: payload.setting,
  };
};

const tesDeleteStudentAnswer = (state, payload) => {
  let tes = payload?.tes;
  if (tes) {
    let filterStudentAnswer =
      state.studentAnswer &&
      state.studentAnswer.filter(
        (i) => i.id + '' !== tes.studentTesId + ''
      );
    return {
      ...state,
      studentAnswer: filterStudentAnswer,
    };
  } else {
    return {
      ...state,
      studentAnswer: [],
    };
  }
};

const tesDelete = (state, payload) => {
  return {
    ...state,
    total: parseInt(state.total) - 1,
    items: [...state.items.filter((i) => i.id + '' !== payload.tes.id + '')],
  };
};

const tesResetSearch = (state, payload) => {
  return {
    ...state,
    search: {
      year: '',
      sem: '',
      jenjang: '',
      tingkat: '',
      kelas: '',
      pelajaran: '',
      published: 'all',
    },
  };
};

export default createReducer(initialState, {
  [TESS_GET]: tessGet,
  [TES_ADD]: tesAdd,
  [TES_GET]: tesGet,
  [STUDENT_ANSWER_GET]: studentAnswerGet,
  [TES_EDIT]: tesEdit,
  [TES_DETAILSCORES]: tesDetailScores,
  [TES_LISTSCORES]: tesListScores,
  [SETTING_GET]: settingGet,
  [TES_DELETESTUDENTANSWER]: tesDeleteStudentAnswer,
  [TES_DELETE]: tesDelete,
  [TES_RESET_SEARCH]: tesResetSearch,
});
