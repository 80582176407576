import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import TextInput from '../../../../app/common/form/TextInput';
import CheckInput from '../../../../app/common/form/CheckInput';
import { reduxForm, Field } from 'redux-form';
import { useHistory } from 'react-router-dom';
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan,
} from 'revalidate';
import { toastr } from 'react-redux-toastr';

function mapState(state) {
  let scope = {};
  if (state.auth && state.auth.isAuth) {
    scope = state.auth.authorities;
  }
  return {
    auth: state.auth,
    scope: scope,
    loading: state.async.loading,
  };
}

const validate = combineValidators({
  nis: composeValidators(
    isRequired({ message: 'NIS harus diisi' }),
    hasLengthGreaterThan(1)({
      message: 'NIS harus memiliki paling sedikit 2 karakter',
    })
  )(),
  name: composeValidators(
    isRequired({ message: 'Nama harus diisi' }),
    hasLengthGreaterThan(1)({
      message: 'Nama harus memiliki paling sedikit 2 karakter',
    })
  )(),
  username: composeValidators(
    isRequired({ message: 'Username harus diisi' }),
    hasLengthGreaterThan(1)({
      message: 'Username harus memiliki paling sedikit 2 karakter',
    })
  )(),
  group: composeValidators(
    isRequired({ message: 'Group harus diisi' }),
    hasLengthGreaterThan(1)({
      message: 'Group harus memiliki paling sedikit 2 karakter',
    })
  )(),
});

function ModalEditForm(props) {
  const {
    auth,
    invalid,
    loading,
    pristine,
    handleSubmit,
    editCbtParticipant,
    closeModal,
    initialValues,
  } = props;
  let history = useHistory();
  const [statusActive, SetStatusActive] = useState(false);

  useEffect(() => {
    SetStatusActive(initialValues.status);
  }, [initialValues]);

  const onStatusChange = () => {
    SetStatusActive(!statusActive)
  }

  const onFormSubmit = (values) => {
    let newValues = {...values, status: statusActive}
    editCbtParticipant(auth, history, newValues).then((res) => {
      if (!res) {
        return;
      }
      closeModal();
      toastr.success('Sukses', `Update data berhasil`);
    });
  };

  return (
    <>
      <section className='modal-card-body is-size-6'>
        <div className='columns'>
          <div className='column is-third-quarter is-capitalized'>
            <form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off'>
              <div className='field is-horizontal'>
                <div className='field-body'>
                  <Field
                    name='nis'
                    type='text'
                    component={TextInput}
                    placeholder='NIS'
                    label='NIS'
                    fullwidth={true}
                  />
                  <Field
                    name='name'
                    type='text'
                    component={TextInput}
                    placeholder='Nama'
                    label='Nama'
                    fullwidth={true}
                  />
                </div>
              </div>
              <Field
                name='username'
                type='text'
                component={TextInput}
                placeholder='Username'
                label='Username'
                fullwidth={true}
              />
              <Field
                name='token'
                type='text'
                component={TextInput}
                placeholder='Token'
                label='Token'
                fullwidth={true}
                readOnly={true}
              />
              <div className='field is-horizontal'>
                <div className='field-body'>
                  <Field
                    name='remarks'
                    type='text'
                    component={TextInput}
                    placeholder='Keterangan'
                    label='Keterangan'
                    fullwidth={true}
                  />
                  <Field
                    name='group'
                    type='text'
                    component={TextInput}
                    placeholder='Grup'
                    label='Grup'
                    fullwidth={true}
                  />
                </div>
              </div>
              <div className='field flex align-center'>
                <div className='mb-5'>
                  <Field
                    name='status'
                    id='status'
                    type='checkbox'
                    isChecked={statusActive}
                    onChange={() => onStatusChange()}
                    component={CheckInput}
                    label={statusActive ? 'Aktif' : 'Non Aktif'}
                    style={{ fontWeight: 'bold' }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <footer className='modal-card-foot'>
        <button
          disabled={invalid || loading || pristine}
          onClick={handleSubmit(onFormSubmit)}
          className={
            loading
              ? 'button is-small is-info is-rounded is-outlined is-loading'
              : 'button is-small is-info is-rounded is-outlined'
          }
        >
          <i className='is-size-6 mdi mdi-content-save icon' />
        </button>
        <button
          className='button custom-grey is-small is-rounded is-outlined'
          onClick={closeModal}
        >
          <i className='is-size-6 mdi mdi-arrow-left icon' />
        </button>
      </footer>
    </>
  );
}

export default connect(
  mapState,
  null
)(
  reduxForm({
    form: 'cbtParticipantedit',
    validate,
    enableReinitialize: true,
  })(ModalEditForm)
);
