import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ChevronRight from '../../../common/icons/ChevronRight';

function Submenu({ subm, authSubm, pathname }) {
  let url = subm.alias;
  const regex = new RegExp(url);
  const isActive = regex.test(pathname);
  const accessSubm = authSubm[subm.id + '_view'];
  const [isHover, setIsHover] = useState(false);

  return (
    <>
      {accessSubm && (
        <li>
          <Link
            onMouseEnter={() => setIsHover(!isHover)}
            onMouseLeave={() => setIsHover(!isHover)}
            to={subm.subUrl}
            className={`is-size-6 custom-text-overflow disable-select${
              isActive === true ? ' is-active' : ''
            }`}
          >
            <span className='flex align-center'>
              <ChevronRight
                size={15}
                color={(isHover || isActive) ? '#42a5f5' : '#ffffff'}
              />
              <span className='ml-1'>{subm.submenuTitle}</span>
            </span>
          </Link>
        </li>
      )}
    </>
  );
}

export default Submenu;
