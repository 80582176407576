import React from 'react';

export default function MSyncScoreFormListItems(props) {
  const {
    item,
    scores
  } = props;
  
  let score = scores.find((i) => i.nis + '' === item?.NIS + '');
  let finalScore = !isNaN(score?.finalScore) ? Math.round(score?.finalScore) : score?.finalScore;

  return (
    <tr>
      <td className='has-text-centered'>{item?.NO_URUT}</td>
      <td className='has-text-centered'>{item?.NIS}</td>
      <td>{item?.NAMA}</td>
      <td className={`has-text-right${score?.finalScore ? '' : ' has-background-danger-light'}`}>{finalScore ?? 0}</td>
    </tr>);
}
