import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deleteSyncScores } from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';

const actions = {
  closeModal,
  deleteSyncScores,
};

class ModalDeleteSyncScore extends Component {
  onDeleteSync = ({ auth, history, id, item }) => {
    const { deleteSyncScores, closeModal } = this.props;
    deleteSyncScores(auth, history, id, item);
    closeModal();
  };

  render() {
    const { data, closeModal } = this.props;
    return (
      <div className='modal is-active'>
        <div className='modal-background' onClick={closeModal}></div>
        <div className='modal-card'>
          <header className='modal-card-head'>
            <div className='modal-card-title has-text-danger has-text-weight-normal'>
              <i className='is-size-4 mdi mdi-shield-half-full icon' />{' '}
              Konfirmasi Delete
            </div>
            <button
              onClick={closeModal}
              className='delete'
              aria-label='close'
            ></button>
          </header>
          <section className='modal-card-body is-size-6'>
            <div style={{display: 'flex', justifyContent: 'space-around', flexDirection: 'column', alignContent: 'center'}}>
              <p className='is-size-5 has-text-centered mb-5 mt-5'>
                Apakah Anda yakin ingin menghapus status Sync Nilai?
              </p>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                <p className='is-size-6 is-italic mb-2'>
                  <span className='tag is-info is-rounded'>
                    Note:
                  </span>
                </p>
                <p className=' is-italic'>1. Jika status sync nilai dihapus, sync nilai akan bisa dilakukan lagi dan tidak menghapus sync nilai yang telah dilakukan.</p>
                <p className=' is-italic'>2. Hapus manual nilai sebelumnya jika ingin melakukan sync nilai lagi, menghindari duplikat nilai.</p>
                <p className=' is-italic'>3. Jika dilakukan sync nilai kembali, kolom nilai yang terisi adalah kolom nilai yang masih kosong.</p>
              </div>
            </div>
          </section>
          <footer className='modal-card-foot'>
            <button
              onClick={() => this.onDeleteSync(data)}
              className='button is-danger is-small is-rounded is-outlined'
            >
              <i className='is-size-6 mdi mdi-delete icon' />
            </button>
            <button
              className='button custom-grey is-small is-rounded is-outlined'
              onClick={closeModal}
            >
              <i className='is-size-6 mdi mdi-arrow-left icon' />
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

export default connect(null, actions)(ModalDeleteSyncScore);
