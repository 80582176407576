import {
  MONITORINGS_GET,
  MONITORING_GET,
  MONITORINGSTUDENTS_GET,
  MONITORINGSTUDENT_GET,
  MONITORING_FILTER,
} from './reduxConstant';

export const monitoringsGet = (monitorings) => {
  return {
    type: MONITORINGS_GET,
    payload: {
      monitorings,
    },
  };
};

export const monitoringGet = (monitoring) => {
  return {
    type: MONITORING_GET,
    payload: {
      monitoring,
    },
  };
};

export const monitoringStudentsGet = (monitorStudents) => {
  return {
    type: MONITORINGSTUDENTS_GET,
    payload: {
      monitorStudents,
    },
  };
};

export const monitoringStudentGet = (monitorStudent) => {
  return {
    type: MONITORINGSTUDENT_GET,
    payload: {
      monitorStudent,
    },
  };
};

export const monitoringFilter = (monitoring) => {
  return {
    type: MONITORING_FILTER,
    payload: {
      monitoring,
    },
  };
};
