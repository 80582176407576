import React from 'react';
import { connect } from 'react-redux';
import NotAuthorized from './MNotAuthorized';
import UserStatus from '../../menus/users/MStatus';
import ResetPassword from '../../menus/users/MResetPassword';
import UserDelete from '../../menus/users/MDeleteItem';
import RoleDelete from '../../menus/roles/MDeleteItem';
import RppFileDelete from '../../menus/_features/rpp/MDeleteFile';
import RppDelete from '../../menus/_features/rpp/MDeleteItem';
import MateriFileDelete from '../../menus/_features/materi/MDeleteFile';
import MateriDelete from '../../menus/_features/materi/MDeleteItem';
import TugasSummaryAnswer from '../../menus/_features/tugas/MViewSummaryAnswer';
import TugasPublishStudentScore from '../../menus/_features/tugas/MPublishStudentScore';
import TugasShareAnswerKey from '../../menus/_features/tugas/MShareAnswerKey';
import TugasDeleteShareAnswerKey from '../../menus/_features/tugas/MDeleteShareAnswerKey';
import TugasSyncScore from '../../menus/_features/tugas/MSyncScore';
import TugasDeleteImage from '../../menus/_features/tugas/MDeleteImage';
import TugasDelete from '../../menus/_features/tugas/MDeleteItem';
import TugasDeleteStudentAnswer from '../../menus/_features/tugas/MDeleteStudentAnswer';
import TugasDeleteAllStudentAnswer from '../../menus/_features/tugas/MDeleteStudentAnswerAll';
import TugasDeleteSyncScore from '../../menus/_features/tugas/MDeleteSyncScore';
import LatihanDeleteImage from '../../menus/_features/latihan/MDeleteImage';
import LatihanDelete from '../../menus/_features/latihan/MDeleteItem';
import TesSummaryAnswer from '../../menus/_features/tes/MViewSummaryAnswer';
import TesPublishStudentScore from '../../menus/_features/tes/MPublishStudentScore';
import TesShareAnswerKey from '../../menus/_features/tes/MShareAnswerKey';
import TesDeleteShareAnswerKey from '../../menus/_features/tes/MDeleteShareAnswerKey';
import TesSyncScore from '../../menus/_features/tes/MSyncScore';
import TesDeleteImage from '../../menus/_features/tes/MDeleteImage';
import TesDelete from '../../menus/_features/tes/MDeleteItem';
import TesDeleteStudentAnswer from '../../menus/_features/tes/MDeleteStudentAnswer';
import TesDeleteAllStudentAnswer from '../../menus/_features/tes/MDeleteStudentAnswerAll';
import TesDeleteSyncScore from '../../menus/_features/tes/MDeleteSyncScore';
import MonitoringEventView from '../../menus/_features/monitoring/MViewEvent';
import MonitoringNoteView from '../../menus/_features/monitoring/MViewNote';
import MonitoringPesanView from '../../menus/_features/monitoring/MViewPesan';
import MonitoringBlockView from '../../menus/_features/monitoring/MViewBlock';
import CbtparticipantEdit from '../../menus/_features/cbt/participant/MEdit';
import CbtDeleteImage from '../../menus/_features/cbt/question/MDeleteImage';
import CbtquestionDeleteCbtparticipantAnswer from '../../menus/_features/cbt/question/MDeleteCbtparticipantAnswer';
import CbtquestionDeleteCbtparticipantAllAnswer from '../../menus/_features/cbt/question/MDeleteCbtparticipantAllAnswer';
import CbtparticipantDelete from '../../menus/_features/cbt/participant/MDeleteItem';
import CbtquestionDelete from '../../menus/_features/cbt/question/MDeleteItem';
import CbtquestionSummaryAnswer from '../../menus/_features/cbt/question/MViewSummaryAnswer';
import CalendarViewEvent from '../../menus/_features/calendar/MViewEvent';
import CalendarDeleteEvent from '../../menus/_features/calendar/MDeleteEvent';
import CalendarDelete from '../../menus/_features/calendar/MDeleteItem';
import CalendarStatus from '../../menus/_features/calendar/MSetActive';
import PesanPreview from '../../menus/_features/pesanpribadi/MPreview';
import PesanInfoAdd from '../../menus/_features/pesanpribadi/MInfoAdd';
import PesanFileDelete from '../../menus/_features/pesanpribadi/MDeleteFile';
import PesanDelete from '../../menus/_features/pesanpribadi/MDeleteItem';
import SlideshowDelete from '../../menus/_features/slideshow/MDeleteItem';
import AnnouncementFileDelete from '../../menus/_features/announcement/MDeleteFile';
import AnnouncementDelete from '../../menus/_features/announcement/MDeleteItem';
import WhatsappPreview from '../../menus/_features/whatsapp/MPreview';
import WhatsappFileDelete from '../../menus/_features/whatsapp/MDeleteFile';
import WhatsappDelete from '../../menus/_features/whatsapp/MDeleteItem';
import WhatsappSettingPopup from '../../menus/_features/whatsapp/MSettingPopup';
import PortaluserPesanDelete from '../../menus/_features/portaluser/MDeletePesanItem';
import PortaluserDeleteAll from '../../menus/_features/portaluser/MDeleteAllPortalusers';
import JenjangStatus from '../../menus/_features/jenjang/MStatus';
import JenjangDelete from '../../menus/_features/jenjang/MDeleteItem';
import TingkatStatus from '../../menus/_features/tingkat/MStatus';
import TingkatDelete from '../../menus/_features/tingkat/MDeleteItem';
import KelasStatus from '../../menus/_features/kelas/MStatus';
import KelasDelete from '../../menus/_features/kelas/MDeleteItem';
import PelajaranStatus from '../../menus/_features/pelajaran/MStatus';
import PelajaranDelete from '../../menus/_features/pelajaran/MDeleteItem';
import YearStatus from '../../menus/_features/year/MStatus';
import YearDelete from '../../menus/_features/year/MDeleteItem';
import PopUpImageModal from '../common/layout/MImagePopup';
import PopUpPdfModal from '../common/layout/MPdfPopup';
import TestModal from '../../menus/tests/ModalTest';

const modalLookup = {
  NotAuthorized,
  UserStatus,
  ResetPassword,
  UserDelete,
  RoleDelete,
  RppFileDelete,
  RppDelete,
  MateriFileDelete,
  MateriDelete,
  TugasSummaryAnswer,
  TugasPublishStudentScore,
  TugasShareAnswerKey,
  TugasDeleteShareAnswerKey,
  TugasSyncScore,
  TugasDeleteImage,
  TugasDeleteStudentAnswer,
  TugasDeleteAllStudentAnswer,
  TugasDelete,
  TugasDeleteSyncScore,
  LatihanDeleteImage,
  LatihanDelete,
  TesSummaryAnswer,
  TesPublishStudentScore,
  TesShareAnswerKey,
  TesDeleteShareAnswerKey,
  TesSyncScore,
  TesDeleteImage,
  TesDeleteStudentAnswer,
  TesDeleteAllStudentAnswer,
  TesDeleteSyncScore,
  TesDelete,
  MonitoringEventView,
  MonitoringNoteView,
  MonitoringPesanView,
  MonitoringBlockView,
  CbtparticipantEdit,
  CbtDeleteImage,
  CbtquestionSummaryAnswer,
  CbtparticipantDelete,
  CbtquestionDelete,
  CbtquestionDeleteCbtparticipantAnswer,
  CbtquestionDeleteCbtparticipantAllAnswer,
  CalendarViewEvent,
  CalendarDeleteEvent,
  CalendarDelete,
  CalendarStatus,
  PesanFileDelete,
  PesanDelete,
  PesanPreview,
  PesanInfoAdd,
  SlideshowDelete,
  AnnouncementFileDelete,
  AnnouncementDelete,
  WhatsappPreview,
  WhatsappFileDelete,
  WhatsappDelete,
  WhatsappSettingPopup,
  PortaluserPesanDelete,
  PortaluserDeleteAll,
  JenjangStatus,
  JenjangDelete,
  TingkatStatus,
  TingkatDelete,
  KelasStatus,
  KelasDelete,
  PelajaranStatus,
  PelajaranDelete,
  YearStatus,
  YearDelete,
  PopUpImageModal,
  PopUpPdfModal,
  TestModal,
};

const mapState = (state) => ({
  currentModal: state.modals,
});

const ModalManager = ({ currentModal }) => {
  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];

    renderedModal = <ModalComponent {...modalProps} />;
  }
  return <span>{renderedModal}</span>;
};

export default connect(mapState)(ModalManager);
