export const CBTQUESTIONS_GET = 'CBTQUESTIONS_GET';
export const CBTQUESTION_ADD = 'CBTQUESTION_ADD';
export const CBTQUESTION_GET = 'CBTQUESTION_GET';
export const CBTQUESTION_EDIT = 'CBTQUESTION_EDIT';
export const CBTPARTICIPANT_GET = 'CBTPARTICIPANT_GET';
export const CBTPARTICIPANT_GROUP_GET = 'CBTPARTICIPANT_GROUP_GET';
export const CBTQUESTION_UPLOADFILE = 'CBTQUESTION_UPLOADFILE';
export const CBTPARTICIPANT_CBTQUESTION_DELETE = 'CBTPARTICIPANT_CBTQUESTION_DELETE';
export const CBTQUESTION_DELETE = 'CBTQUESTION_DELETE';
export const CBTQUESTION_RESET_SEARCH = 'CBTQUESTION_RESET_SEARCH';
