export const TESS_GET = 'TESS_GET';
export const TES_ADD = 'TES_ADD';
export const TES_GET = 'TES_GET';
export const STUDENT_ANSWER_GET = 'STUDENT_ANSWER_GET';
export const TES_EDIT = 'TES_EDIT';
export const TES_DETAILSCORES = 'TES_DETAILSCORES';
export const TES_LISTSCORES = 'TES_LISTSCORES';
export const SETTING_GET = 'SETTING_GET';
export const TES_DELETESTUDENTANSWER = 'TES_DELETESTUDENTANSWER';
export const TES_DELETE = 'TES_DELETE';
export const TES_RESET_SEARCH = 'TES_RESET_SEARCH';
