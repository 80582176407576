import {
  CBTQUESTIONS_GET,
  CBTQUESTION_ADD,
  CBTQUESTION_GET,
  CBTQUESTION_EDIT,
  CBTPARTICIPANT_GET,
  CBTPARTICIPANT_CBTQUESTION_DELETE,
  CBTPARTICIPANT_GROUP_GET,
  CBTQUESTION_UPLOADFILE,
  CBTQUESTION_DELETE,
  CBTQUESTION_RESET_SEARCH,
} from './reduxConstant';

export const cbtquestionsGet = (cbtquestions) => {
  return {
    type: CBTQUESTIONS_GET,
    payload: {
      cbtquestions,
    },
  };
};

export const cbtquestionAdd = (cbtquestion) => {
  return {
    type: CBTQUESTION_ADD,
    payload: {
      cbtquestion,
    },
  };
};

export const cbtquestionGet = (cbtquestion) => {
  return {
    type: CBTQUESTION_GET,
    payload: {
      cbtquestion,
    },
  };
};

export const cbtquestionEdit = (cbtquestion) => {
  return {
    type: CBTQUESTION_EDIT,
    payload: {
      cbtquestion,
    },
  };
};

export const cbtparticipantGet = (cbtparticipants) => {
  return {
    type: CBTPARTICIPANT_GET,
    payload: {
      cbtparticipants,
    },
  };
};

export const cbtparticipantCbtquestionDelete= (cbtparticipant) => {
  return {
    type: CBTPARTICIPANT_CBTQUESTION_DELETE,
    payload: {
      cbtparticipant,
    },
  };
};

export const cbtparticipantGroupGet = (groups) => {
  return {
    type: CBTPARTICIPANT_GROUP_GET,
    payload: {
      groups,
    },
  };
};

export const cbtquestionUploadFile = (uploadFile) => {
  return {
    type: CBTQUESTION_UPLOADFILE,
    payload: {
      uploadFile,
    },
  };
};

export const cbtquestionDelete = (cbtquestion) => {
  return {
    type: CBTQUESTION_DELETE,
    payload: {
      cbtquestion,
    },
  };
};

export const cbtquestionResetSearch = () => {
  return {
    type: CBTQUESTION_RESET_SEARCH,
    payload: {},
  };
};
