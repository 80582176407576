import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  sendListTugasScores,
  getDetailTugasScores,
  getListTugasScores,
} from './redux/reduxApi';
import { closeModal } from '../../../app/modals/redux/modalActions';
import MSyncScoreForm from './MSyncScoreForm';
import { uniqueValueObject } from '../../../app/common/helpers/objectHelpers';

const mapState = (state, ownProps) => {
  return {
    detailScores: state.tugass.detailScores,
    listScores: state.tugass.listScores,
    studentAnswer: state.tugass.studentAnswer,
  };
};

const actions = {
  closeModal,
  getDetailTugasScores,
  getListTugasScores,
  sendListTugasScores,
};

function ModalSyncScore(props) {
  const {
    data,
    closeModal,
    detailScores,
    listScores,
    studentAnswer,
    getDetailTugasScores,
    getListTugasScores,
    sendListTugasScores,
  } = props;
  const { auth, history, id, item } = data;
  const scores = item?.scores;

  let uniqueKelas = uniqueValueObject(studentAnswer, 'kelas');
  let optionKelas = [];
  for (let i = 0; i < uniqueKelas.length; i++) {
    const item = uniqueKelas[i];
    let newItem = {
      key: item,
      text: item,
      value: item,
    };
    optionKelas = [...optionKelas, newItem];
  }

  useEffect(() => {
    let newValues = {
      ...item,
      kelas: uniqueKelas[0],
    };
    getDetailTugasScores(auth, history, id, newValues);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className='modal is-active'>
      <div className='modal-background' onClick={closeModal}></div>
      <div className='modal-card'>
        <header className='modal-card-head has-background-info'>
          <div className='modal-card-title has-text-white has-text-weight-normal is-size-5'>
            <i className='mdi mdi-file-sync icon' /> Sync Nilai
          </div>
          <button
            onClick={closeModal}
            className='delete'
            aria-label='close'
          ></button>
        </header>
        <section className='modal-card-body is-size-6' style={{paddingTop: 0, margin: 0}}>
          <div className='card-content pl-4 pr-4'>
            <MSyncScoreForm
              auth={auth}
              history={history}
              id={id}
              item={item}
              scores={scores}
              detailScores={detailScores}
              listScores={listScores}
              getDetailTugasScores={getDetailTugasScores}
              getListTugasScores={getListTugasScores}
              optionKelas={optionKelas}
              sendListTugasScores={sendListTugasScores}
              closeModal={closeModal}
            />
          </div>
        </section>
        <footer className='modal-card-foot has-background-info'></footer>
      </div>
    </div>
  );
}

export default connect(mapState, actions)(ModalSyncScore);
